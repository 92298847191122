.cta-box {
  //! duplicate button styles bcoz parent also have bg
  &.has-white-bg {
    *,
    p {
      color: $dark-grey;
    }
    .btn {
      &.is-primary {
        // padding: 1.125rem 2.8125rem;  //ask desing about paddings
        padding: 1rem 2rem;
        border: 1px solid $light-grey;
        color: $dark-grey;
        div {
          position: absolute;
          background: $aunds-gradient;
          transition: all 350ms ease-in-out;

          &:first-of-type {
            top: -1px;
            left: -1px;
            transform: rotate(180deg);

            height: 30px;
            width: 1px;
          }
          &:nth-of-type(2) {
            top: -1px;
            left: -1px;

            height: 1px;
            width: 30px;
          }
          &:nth-of-type(3) {
            bottom: -1px;
            right: -1px;
            transform: rotate(180deg);

            height: 1px;
            width: 30px;
          }
          &:last-of-type {
            bottom: -1px;
            right: -1px;

            height: 30px;
            width: 1px;
          }
        }

        &:hover,
        &:focus {
          text-decoration-line: none;
          color: $dark-grey;

          div {
            &:first-of-type,
            &:last-of-type {
              height: 103.5%;
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
              width: 100.75%;
            }
          }
        }
      }
    }
    .has-grey-bg {
      background-color: $light-grey;
      .btn {
        &.is-primary {
          border: 1px solid $white;
          color: $dark-grey;

          &:hover {
            color: $dark-grey;
          }
        }
      }
    }
    &.has-darkgrey-bg,
    &.has-dark-grey-bg {
      background-color: $dark-grey;
      * {
        color: $white;
      }
      svg {
        path {
          stroke: $white;
        }
      }
      *::-moz-selection {
        color: $text;
        background: $white;
        text-shadow: none;
      }

      *::selection {
        color: $text;
        background: $white;
        text-shadow: none;
      }
      .btn {
        &.is-primary {
          border: 1px solid $white;
          color: $white;

          &:hover {
            color: $white;
          }
        }
      }
    }
    &.has-gradient-bg {
      background: $aunds-gradient;
      * {
        color: $white;
      }
      svg {
        path {
          stroke: $white;
        }
      }
      *::-moz-selection {
        color: $text;
        background: $white;
        text-shadow: none;
      }

      *::selection {
        color: $text;
        background: $white;
        text-shadow: none;
      }
      .btn {
        &.is-primary {
          border: 1px solid $white-50;
          color: $white;
          div {
            background: $white;
          }
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 1.5rem;
  // margin-top: 53.5px; // half of image height
  @include desktop {
    max-width: 80%;
  }

  margin-top: 57.5px;
  @include desktop {
    margin-top: 0;
  }

  .media-wrapper {
    width: 107px;
    height: 107px;
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transform: translateY(-50%);
    background: $light-grey;
    border-radius: 50%;
    overflow: hidden;
    img {
      border-radius: 50%;
      width: 107px;
      height: 107px;
      object-fit: cover;
    }

    div {
      height: inherit;
    }
  }

  .cta-info {
    margin-top: 1rem;
    display: grid;
    justify-items: center;
    gap: 1.5rem;

    .subline {
      font-size: 0.875rem;
      line-height: 1.875rem;
    }

    .cta-content {
      text-align: center;
      * {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: $sans-serif-medium;
        @include tablet {
          font-size: 1.5rem;
          line-height: 1.875rem;
        }
      }
    }
  }
}

.has-white-bg {
  .cta-box {
    background: $light-grey;
    .media-wrapper {
      background: $light-grey;
    }

    .btn {
      &.is-primary {
        border: 1px solid $white;
      }
    }
  }
}
