.media .container {
  @include mobile_padding;

  .video-wrapper {
    display: flex;
    align-items: center;
    overflow: hidden;
    // -moz-border-radius: 6px;
    // -webkit-border-radius: 6px;
    // border-radius: 6px;

    .thumbnail {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: 16 / 9;

      @include tablet {
        filter: blur(3px);
      }

      @include desktop {
        filter: blur(4px);
      }
    }
  }
}

.content-blocker-video-wrapper {
  display: flex;
  align-items: center;
  // -moz-border-radius: 6px;
  // -webkit-border-radius: 6px;
  // border-radius: 6px;
  width: 100%;
  height: 100%;

  .thumbnail {
    width: 100%;
    object-fit: cover;
    aspect-ratio: 16 / 9;

    @include tablet {
      filter: blur(3px);
    }

    @include desktop {
      filter: blur(4px);
    }
  }
}

.grey {
  background-color: lightgrey;
}

.white {
  background-color: white;
}

.image-caption {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}
.has-slider {
  position: relative;
  overflow: hidden;
  background-color: $red;
  color: $white;
}
.slide {
  padding: 3rem;
  span {
    max-height: 20rem;
  }
}
.is-left-arrow,
.is-right-arrow {
  position: absolute;
  top: 50%;
}
.is-right-arrow {
  right: 12%;
}

.is-header-small {
  height: 20rem;
  span {
    height: 20rem;
    min-height: 20rem;
    max-height: 20rem;
  }
}

.iframe-box {
  aspect-ratio: 16 / 9;
  position: relative;
  overflow: hidden;
  background-color: #f3f3f2;
  // -moz-border-radius: 6px;
  // -webkit-border-radius: 6px;
  // border-radius: 6px;
  // height: 100%;
  width: 100%;

  .iframe-wrapper {
    width: 100%;
    height: 100%;
  }

  .iframe-play-button {
    width: 70px;
    height: 46px;
    background-color: #212121;
    z-index: 1;
    opacity: 0.8;
    border-radius: 14%;
    transition: all 0.2s cubic-bezier(0, 0, 0.2, 1);
    border: none;
    cursor: pointer;
    position: relative;

    &::before {
      content: "";
      border-color: transparent transparent transparent #fff;
      border-style: solid;
      border-width: 11px 0 11px 19px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  &:hover {
    .iframe-play-button {
      &.youtube {
        background-color: red;
      }
      &.vimeo {
        background-color: #17d5ff;
      }

      opacity: 1;
    }
  }

  .poster {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &::after {
      position: absolute;
      content: "";
      background: #2b2b2b 0% 0% no-repeat padding-box;
      opacity: 0.3;
      width: 100%;
      height: 100%;
    }

    svg {
      position: relative;
      width: 15%;
      z-index: 2;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}

.inline-video {
  position: relative;
  cursor: pointer;

  .inline-video-poster {
    position: absolute;
    z-index: 2;
    left: 0;
    height: 100%;
    object-fit: "cover";
  }

  .media-control {
    transition: opacity 0.3s ease-in;
    z-index: 3;
  }
  &:hover {
    .seek-bar {
      opacity: 1;
    }
  }

  &.media-paying {
    .media-control {
      opacity: 0;
    }

    &:hover {
      .media-control {
        opacity: 1;
      }
    }
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
  }

  .media-control {
    position: absolute;
    display: flex;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    .media-control-icons {
      cursor: pointer;
    }
  }

  .sound-toggle {
    position: absolute;
    display: flex;
    bottom: 0;
    right: 0;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
    z-index: 3;

    .sound-toogle-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }

  .seek-bar {
    z-index: 10;
    position: absolute;
    transition: opacity 0.3s ease-in;
    width: 89%;
    height: 5px;
    bottom: 2.3125rem;
    left: 2.5%;
    border-radius: 10px;
    display: flex;
    background: $white;
    opacity: 0;

    .progress {
      transition: width 0.5s;
      display: inline-block;
      width: 1px;
      height: 5px;
      background: $magenta;
      border-radius: 10px;
    }
  }
}
