.form-contact {
  * {
    color: $dark-grey;
  }

  .form-title {
    font-family: $sans-serif-bold;
    font-size: 1.5rem;
    line-height: 1;
  }

  .mini-text {
    font-size: 0.75rem;
    line-height: 1;
  }

  .policy-check {
    display: grid;
    gap: 1.5rem;
  }

  .error-message {
    color: $red;
    font-size: 0.75rem;
  }
}

.form-wrapper {
  position: relative;
  color: inherit;
  padding: 2.5rem;
  background: $white;
  outline: $light-grey solid 2px;
  outline-offset: -2px;

  @include tablet {
    padding: 3.625rem;
  }

  &::after,
  &::before {
    transition: opacity 0.3s ease-in, width 0.3s ease-in-out,
      height 0.3s ease-in-out;
    position: absolute;
    width: 60px;
    height: 60px;
    background: $aunds-gradient;
    content: " ";
    z-index: 1;
  }

  &::after {
    top: 0;
    left: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
    transform: rotate(270deg);
  }
  &::before {
    top: 0;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
  }

  .corner-div {
    position: absolute;
    transition: opacity 0.3s ease-in, width 0.3s ease-in-out,
      height 0.3s ease-in-out;
    width: 60px;
    height: 60px;
    background: $aunds-gradient;

    &:nth-of-type(2) {
      bottom: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      transform: rotate(180deg);
    }

    &:nth-of-type(3) {
      bottom: 0;
      right: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      transform: rotate(90deg);
    }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    .close-button {
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}

.form-state {
  // position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .form-state-content {
    .bold-text {
      font-size: 32px;
      line-height: 1;
      font-family: $sans-serif-bold;
    }
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $red;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
