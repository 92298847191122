.page-not-found {
  &.border-on {
    padding: 2rem;

    @include tablet {
      padding: 2.25rem;
    }

    &.fullscreen {
      height: calc(40vh - $navbar-height-touch);

      @include tablet {
        height: calc(50vh - $navbar-height-touch);
      }

      @include desktop {
        height: calc(100vh - $navbar-height-desktop);
      }

      .hero-media-wrapper {
        max-height: calc(100vh - $navbar-height-touch - 1rem);
        @include tablet {
          max-height: calc(100vh - $navbar-height-touch - 4.5rem);
        }
        @include desktop {
          max-height: calc(100vh - $navbar-height-desktop - 4.5rem);
        }
      }
    }
  }

  height: 100%;

  .frame {
    position: relative;
    // height: 100%;

    .border {
      height: 100%;
      overflow: hidden;

      .columns {
        height: 100%;
        width: 100%;
        margin: 0;
        position: relative;
        z-index: 1;
      }

      .background-full-media {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        .background-media {
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    &.frame-on {
      .border {
        border: 2px solid $white-50;
      }

      &::after,
      &::before {
        position: absolute;
        background: $white;
        content: " ";

        @include mobile {
          width: 40px;
          height: 40px;
        }
        width: 80px;
        height: 80px;
      }

      &::after {
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
        transform: rotate(270deg);
      }

      &::before {
        top: 0;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      }

      .frame-edge {
        position: absolute;
        background: $white;

        @include mobile {
          width: 40px;
          height: 40px;
        }

        width: 80px;
        height: 80px;

        &:nth-of-type(2) {
          bottom: 0;
          left: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(180deg);
        }

        &:nth-of-type(3) {
          bottom: 0;
          right: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(90deg);
        }
      }
    }
  }

  .content {
    p:first-child {
      padding-bottom: 0;
    }
  }

  .main-inside-wrapper {
    padding: 0 2rem;
    width: 100%;
  }

  .error-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .error-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;
  }
}

.error-info-wrapper {
  &.bottom {
    padding: 0 2rem;
  }
  .title {
    font-family: $sans-serif-bold;
    color: rgba(249, 249, 249, 0.26);
    margin: 0;
    line-height: 1;

    font-size: 120px;

    @include tablet {
      font-size: 220px;
    }

    @include widescreen {
      font-size: 169px;
    }

    @include fullhd {
      font-size: 200px;
    }

    @include ultrahd {
      font-size: 10vw;
    }
  }

  .subline {
    font-family: $sans-serif-bold;
    line-height: 42px;
    font-size: 32px;
    display: inline-block;

    @include tablet {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .link-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  & .link-wrapper:first-child {
    margin-top: 0.5rem;
  }

  .link-wrapper {
    display: flex;
    text-transform: uppercase;

    a,
    .a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      cursor: pointer;

      span {
        width: 175px;
        text-transform: uppercase;
      }
      svg {
        width: 22px;
        height: 15px;
      }
    }
  }
}
