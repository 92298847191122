.text-media {
  .column {
    &.text-col,
    &.media-col {
      // padding: 30px;
      @include tablet-only {
        padding: 1rem;
      }
    }
  }

  .btn {
    margin: 2rem 0;
  }

  .text-col {
    h1,
    h2 {
      margin: 0;

      strong {
        * {
          font-size: inherit;
          font-family: inherit;
        }
      }
    }
  }
  .links-box {
    flex-direction: column;
    @include tablet {
      flex-direction: row;
      justify-content: center;
      .btn {
        margin-right: 1rem;
      }
      > span:nth-last-child(1) {
        .btn {
          margin-right: 0;
        }
      }
    }
    @include desktop {
      justify-content: start;
    }
  }

  .space-content {
    @include desktop {
      margin-top: 5rem;
    }
  }

  ul {
    margin-left: 2rem;
  }
}

.text-media-header {
  &.image-wrapper {
    height: 50vh;

    .image-caption {
      margin-bottom: 30px;
    }
  }

  .arrow:before,
  .arrow:after {
    content: "";
    position: absolute;
    width: 50vw;
    bottom: 0;
    height: 30px; //arrow height
    background: #fff;
  }
  .arrow:before {
    right: 30px; //arrow width / 2
  }
  .arrow:after {
    left: 30px; //arrow width / 2
  }
  .arrow {
    border-top: 30px solid transparent; //arrow height
    border-right: 30px solid white; //arrow width / 2
    border-left: 30px solid white; //arrow width / 2
    position: absolute;
    left: calc(50% - 30px);
    bottom: 0;

    &-has-grey-bg {
      border-right: 30px solid $light-grey; //arrow width / 2
      border-left: 30px solid $light-grey; //arrow width / 2

      &:before,
      &:after {
        background: $light-grey;
      }
    }
  }
}
