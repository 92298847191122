@import "../styles/resets";

.breadcrumbs {
  // margin-left: 1.7rem;

  display: flex;
  justify-content: center;
  $breadcrumb-txt-color: $grey;
  color: $breadcrumb-txt-color;
  a {
    color: $breadcrumb-txt-color;
  }
  span {
    font-size: 0.75rem;
    color: $breadcrumb-txt-color;
    display: inline-block;
    padding-inline: 0.125rem;
  }
  .breadcrumbs-active {
    font-family: $sans-serif-bold;
  }

  ul {
    @include ul-reset;
    display: flex;
  }
}

.has-dark-grey-bg,
.has-darkgrey-bg,
.has-gradient-bg {
  $breadcrumb-txt-color: #ffff;
  .breadcrumbs {
    a {
      color: $breadcrumb-txt-color;
    }
    span {
      font-size: 0.75rem;
      color: $breadcrumb-txt-color;
    }
  }
}
