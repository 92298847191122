.inline-nav {
  position: fixed;
  top: calc(50% - $navbar-height-desktop);
  // right: 0.78em;
  right: 0;
  z-index: 2;
  display: flex;
  padding: 1rem 0 1rem 1rem;
  overflow: hidden;
  pointer-events: none;
  .action-wrapper {
    pointer-events: all;
    button {
      background: transparent;
      border: none;
      transform: rotate(180deg);
      height: 100%;
      width: 100%;
      color: inherit;
    }
    width: 60px;
    height: 60px;
    background: $white;
    box-shadow: -5px 5px 5px 0px #00000005;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 1px;
    z-index: 3;
  }

  .link-wrapper {
    box-shadow: 0px 1px 5px 2px #00000005;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 3rem;

    .scroll-link-wrapper {
      pointer-events: all;
      display: flex;
      text-transform: uppercase;
      * {
        color: black;
      }
      a,
      .a {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        gap: 1rem;
        @include line__animtion;
        color: $dark-grey;
        cursor: pointer;

        span {
          max-width: 200px;
          &::after {
            background: $dark-grey;
          }
        }

        svg {
          width: 22px;
          height: 15px;
        }
      }
    }
  }
}
