.promo-banner {
  .container {
    @include mobile {
      padding: 0 !important;
    }
  }

  .promobanner-columns {
    position: relative;
    padding: 30px;
    @include desktop {
      padding: 3rem;
    }

    @include desktop {
      &::after {
        content: "";
        position: absolute;
        height: 80%;
        width: 1px;
        top: 10%;
        left: 50%;
        right: 50%;
      }
    }

    &.has-white-bg {
      &::after {
        background: $dark-grey;
      }
      .promo-banner-left {
        border-bottom: 1px solid $dark-grey;

        @include desktop {
          border-bottom: none;
        }
      }
    }

    &.has-gradient-bg {
      &::after {
        background: $white;
      }
      .promo-banner-left {
        border-bottom: 1px solid $white;
        @include desktop {
          border-bottom: none;
        }
      }
    }

    &.has-grey-bg {
      &::after {
        background: $dark-grey;
      }
      .promo-banner-left {
        border-bottom: 1px solid $dark-grey;
        @include desktop {
          border-bottom: none;
        }
      }
    }

    &.has-darkgrey-bg,
    &.has-dark-grey-bg {
      &::after {
        background: $white;
      }
      .promo-banner-left {
        border-bottom: 1px solid $white;
        @include desktop {
          border-bottom: none;
        }
      }
    }
  }

  .promo-banner-right {
    text-align: center;
    @include touch {
      padding-top: 30px;
    }
    @include desktop {
      text-align: left;
      padding-left: 3.125rem;
    }
  }

  .promo-banner-left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;

    @include touch {
      padding-bottom: 30px;
    }

    @include widescreen {
      text-align: left;
    }

    @include desktop {
      justify-content: start;
      align-items: flex-start;
    }

    .headline-wrapper {
      @include widescreen {
        padding-right: 3.125rem;
      }
    }

    &.text-only {
      @include desktop {
        align-items: flex-end;
        padding-right: 0px;
      }
    }

    .subline {
      display: block;
    }

    img {
      display: block;
    }

    .promo-image-headline {
      // gap: 1.5rem;

      // @include desktop {
      //   gap: 0;
      // }
      .promo-image-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        .is-140x140 {
          border-radius: 50%;
        }
        img {
          object-fit: cover;
          aspect-ratio: 1 / 1;
          height: 100%;
          width: 100%;
          max-width: 137px;
        }
      }
    }
  }

  .promo-banner-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    // gap: 1rem;

    @include desktop {
      justify-content: start;
      align-items: flex-start;
    }

    .promo-banner-content {
      p {
        &:first-child {
          padding: 0;
        }
        padding: 1rem 0 0;
      }
    }
  }
}
