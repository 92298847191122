.wiki-header {
  .container {
    @include mobile_padding;
  }
  .column-cta {
    position: relative;
    @include desktop {
      margin-bottom: -96px;
    }
  }

  .max-width {
    @include widescreen {
      max-width: 55rem;
    }
  }

  .wiki-cta-box {
    padding: 1.5rem 2rem;
    z-index: 1;
    width: clamp(250px, 30vw, 380px);
    max-width: unset;
    margin-left: 1rem;

    * {
      color: $white !important;
      text-align: center;
    }

    .action-arrow {
      pointer-events: all;
      width: 60px;
      height: 60px;
      box-shadow: -5px 5px 5px 0 #00000005;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      left: 1px;
      z-index: 3;
      position: absolute;
      left: -59px;
      // top: 50%;
      // transform: translateY(-50%);
      bottom: 0;

      svg {
        stroke-width: 2;
        transform: rotate(-180deg);
      }

      @include widescreen {
        display: none;
      }
    }

    &.fixed-top {
      position: fixed;
      height: min-content;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @include widescreen {
        transform: none;
        top: 10rem;
        right: unset;
      }
    }
  }

  .subline {
    font-family: $sans-serif-book;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    display: inline-block;
    @include tablet {
      margin-bottom: 3rem;
    }
  }

  & ~ .section {
    h2 {
      font-size: 1.25rem;
      font-family: $sans-serif-medium;
      line-height: 1.75rem;

      @include tablet {
        font-size: 1.5rem;
        line-height: 2rem;
      }

      strong {
        font-family: $sans-serif-bold;
        font-weight: normal;
      }
    }

    &.media,
    &.text {
      .column {
        max-width: 100%;
        @include widescreen {
          max-width: 55rem;
        }
      }
    }
  }

  &.cta {
    & ~ .section {
      &.media,
      &.text {
        .columns {
          &.is-centered {
            justify-content: flex-start;
          }
        }
      }
    }
  }
}

.wiki-categories {
  .wiki-category {
    font-family: $sans-serif-book;
    padding: 0 1rem;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    color: $text;
    margin-bottom: 1rem;
    white-space: nowrap;

    &::after {
      content: "";
      width: 1px;
      height: calc(100% - 6px);
      top: 3px;
      position: absolute;
      right: 0;
      background-color: $text;
    }

    &:hover {
      color: $red;
    }

    &:last-of-type {
      &::after {
        display: none;
      }
    }
  }

  .categories-slider {
    display: flex;
    align-items: center;

    .embla-slider {
      @include reset-ul-li;
      overflow: hidden;
      .embla-container {
        display: flex;
        height: 100%;

        .embla-slide {
          min-width: 0;
          height: 100%;
          flex: 0 0 100%;

          @media screen and (min-width: 485px) {
            flex: 0 0 50%;
          }

          @include tablet {
            flex: 0 0 33.33333%;
          }
        }
      }
    }

    .wiki-category {
      display: flex;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  .prev,
  .next {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: inherit;
  }
}
