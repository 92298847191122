.loading-screen {
  position: absolute;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 4;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: $modal-background-background-color;
  .loader-wrapper {
    height: 50%;
  }
}

.fullscreen-loader {
  position: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 50;
  left: 0;
  right: 0;
  // height: 100vh;
  width: 100vw;
  background-color: $white;
  @include height__without_navbar;

  .loader-wrapper {
    width: 300px;
  }
}
