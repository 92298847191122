.awards {
  &.has-darkgrey-bg,
  &.has-dark-grey-bg,
  &.has-gradient-bg {
    .line-wrapper {
      .line {
        border-bottom: 1px solid $medium-grey;
      }
    }

    .middle-line {
      &::after {
        background-color: $medium-grey;
      }
    }

    .border-right {
      @include widescreen {
        border-right: 1px solid $medium-grey;
      }
    }
  }
  .awards-slider {
    ul {
      li {
        padding: 0;
        &::after,
        &::before {
          content: none;
          display: none;
        }
      }
    }
  }

  .embla-slider {
    @include reset-ul-li;

    .embla-container {
      display: flex;
      align-items: center;
      margin: 0;
      height: 100%;
      .embla-slide {
        position: relative;
        min-width: 0;
        margin-right: 1rem;
        height: 100%;
        flex: 0 0 50%;
        @include tablet {
          flex: 0 0 25%;
        }
      }
    }
  }

  .line-wrapper {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    .line {
      content: "";
      flex: 1 1 auto;
      border-bottom: 1px solid $dark-grey;
    }
  }

  .overline-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1 1 auto;
    text-transform: none;
    .overline-text {
      font-size: 1rem;
      text-transform: uppercase;
    }

    .line-wrapper {
      margin-left: 0.5rem;
    }
  }

  .bottom-line-wrapper {
    min-height: 1rem;
    display: flex;
    align-items: center;
  }

  .awards-link {
    display: flex;
    flex-wrap: nowrap;
    gap: 1.5rem;
    align-items: center;

    span {
      display: block;
    }

    .arrow {
      transition: transform 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    &:hover {
      .arrow {
        transform: translateX(0.5rem);
      }
    }
  }

  .middle-line {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 100%;
      background-color: $dark-grey;
    }
  }

  .border-right {
    @include widescreen {
      border-right: 1px solid $dark-grey;
    }
  }

  .award-elements {
    padding-top: 0;
    padding-bottom: 0;

    display: flex;
    align-items: center;
    &.is-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      align-items: center;
      gap: 1rem;
    }

    &.is-slider {
      padding-right: 0;
    }
  }

  .tablet-cta-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .award-element {
    display: flex;
    background-color: #f8f8f8;
    aspect-ratio: 1 / 1;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;

    &::after {
      content: none;
    }
  }
}
