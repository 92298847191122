.insights-module {
  position: relative;
  z-index: 2;

  &.has-gradient-bg,
  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .category-item {
      border-right: 1px solid $white;
      .category-item {
        span {
          &::after {
            background: $white;
          }
        }
      }
    }
  }

  &.insight-slider {
    padding: 1rem;
    @include mobile_padding;

    @include tablet {
      padding: $section-padding-tablet 0;
    }

    @include desktop {
      padding: $section-padding-desktop 0;
    }

    @include ultrahd {
      padding: $section-padding-ultrahd 0;
    }

    .embla-slider {
      @include reset-ul-li;
      overflow: hidden;
      .embla-container {
        display: flex;
        height: 100%;

        .embla-slide {
          min-width: 0;
          height: auto;
          flex: 0 0 100%;
          margin-right: 20px;

          @include tablet {
            flex: 0 0 33.33333%;
          }

          @include widescreen {
            flex: 0 0 25%;
          }

          a,
          .a {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  .category-grid {
    display: flex;
    gap: 1rem;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    @include tablet {
      flex-wrap: wrap;
      justify-content: center;
      overflow-x: hidden;
    }

    .category-item {
      text-decoration: none;
      color: inherit;
      padding: 2px 1.5rem;
      display: flex;
      justify-content: center;
      border-right: 1px solid $dark-grey;
      white-space: nowrap;
      line-height: 1;
      &:last-of-type {
        border: none;
      }

      &.active-cat {
        span {
          position: relative;
          &::after {
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            content: "";
            background: $dark-grey;
            z-index: 2;
            height: 1px;
            width: 100%;
            -webkit-transition: width 0.5s ease-in-out;
            -moz-transition: width 0.5s ease-in-out;
            -o-transition: width 0.5s ease-in-out;
            transition: width 0.5s ease-in-out;
            border-radius: 1px;
          }
        }
      }

      @include line__animtion;

      span {
        &::after {
          background: $dark-grey;
        }
      }
    }
  }

  .insight-elements {
    position: relative;
    .insight-link {
      text-decoration: none;
      color: inherit;
    }
  }

  .insight-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1rem 0;
    .insight-info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      .image-wrapper {
        display: flex;
        aspect-ratio: 16 / 9;
        position: relative;
        padding-bottom: 53.3333%;
      }

      .insight-info {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        .categories {
          display: flex;
          column-gap: 1rem;
          flex-wrap: wrap;
          span {
            font-size: 0.75rem;
            line-height: 1.875rem;
          }
        }

        .title {
          font-family: $sans-serif-bold;
        }
      }
    }

    .autor-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        font-size: 0.75rem;
        line-height: 1.875rem;
        display: flex;
        flex-wrap: wrap;
      }
      .arrow {
        display: flex;
        align-items: center;
      }
      a {
        color: inherit;
        white-space: nowrap;
        font-size: 0.75rem;
        line-height: 1.875rem;
      }
    }
  }

  .cta-wrapper {
    display: flex;
    justify-content: center;
  }

  .swiper-wrapper {
    box-sizing: border-box;
    .swiper-slide {
      height: auto;
    }
  }

  .insight-element-big {
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 60px;
    }

    @include widescreen {
      margin-bottom: 80px;
    }

    .info-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
    }

    .categories {
      display: flex;
      column-gap: 1rem;
      flex-wrap: wrap;
      span {
        font-size: 0.75rem;
        line-height: 1.875rem;
        color: $red;
      }
    }

    .preview-image-big {
      position: relative;
      aspect-ratio: 16 / 9;
      overflow: hidden;

      img {
        z-index: 1;
        transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      &.loading {
        &::before {
          width: 100%;
          height: 100%;
          content: "";
          background-color: $dark-grey;
          position: absolute;
        }

        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          content: "";
          transform: translateY(-100%);

          background-image: linear-gradient(
            180deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
          );
          animation: shimmer 2s infinite;
        }
      }
      @keyframes shimmer {
        100% {
          transform: translateY(100%);
        }
      }
    }

    .autor-info {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .info {
        font-size: 0.75rem;
        line-height: 1.875rem;
        display: flex;
        flex-wrap: wrap;
      }
      .arrow {
        display: flex;
        align-items: center;
      }
      a {
        color: inherit;
        white-space: nowrap;
        font-size: 0.75rem;
        line-height: 1.875rem;
      }
    }
  }

  .insight-list-element-wrapper {
    .insight-list-element {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: 1rem;

      padding: 0 0 1.875rem;

      &:not(:first-child) {
        border-top: 1px solid $dark-grey;
        padding: 1.875rem 0;
      }

      .categories {
        display: flex;
        column-gap: 1rem;
        flex-wrap: wrap;
        span {
          font-size: 0.75rem;
          line-height: 1.875rem;
          color: $red;
        }
        &-link {
          @include touch {
            min-height: 3rem;
          }
        }
      }

      .information {
        display: grid;
        grid-template-columns: 80% 20%;

        .arrow-wrapper {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        }

        &:hover {
          .arrow-wrapper {
            transform: translateX(5px);
          }
        }
      }
    }
  }
}
