.cta-box-header {
  .container {
    @include mobile_padding;
  }

  &.fullscreen {
    min-height: calc(100vh - $navbar-height-touch);

    @include desktop {
      min-height: calc(100vh - $navbar-height-desktop);
    }
  }

  &.halfscreen {
    min-height: 50vh;
  }

  &.is-single-page {
    &.fullscreen {
      min-height: calc(100vh - $navbar-height-touch);
    }
  }
}
