.project-header {
  @include tablet {
    padding: 1rem;
  }

  .container {
    @include mobile_padding;
  }

  &.has-dark-grey-bg,
  &.has-darkgrey-bg {
    &.split-gradient {
      background: linear-gradient($dark-grey calc(100% - 8rem), $white 8rem);
    }
  }

  .project-details-wrapper {
    margin-top: 2rem;
    @include tablet {
      padding: 0 1rem;
    }

    .project-details {
      flex-direction: column;

      @include desktop {
        flex-direction: row;
      }

      position: relative;
      .overline {
        display: inline-block;
        font-size: 0.875rem;
      }

      .navigation {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        max-width: 250px;

        margin: 1.5rem 0;

        @include tablet {
          margin: 2.5rem 0;
        }

        @include desktop {
          margin: 0;
        }

        & .scroll-link-wrapper:first-child {
          margin-top: 0.5rem;
        }

        .scroll-link-wrapper {
          display: flex;
          text-transform: uppercase;

          a,
          .a {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            text-decoration: none;
            @include line__animtion;

            cursor: pointer;

            span {
              max-width: 200px;
            }

            svg {
              width: 22px;
              height: 15px;
            }
          }
        }
      }

      .categories {
        position: relative;
        margin-top: 2rem;
        padding: 0.5rem 0;
        display: none;
        @include tablet {
          display: inline-block;
        }

        & span:first-child {
          padding-left: 0;
        }

        &.nav-on,
        &.nav-off {
          &:after {
            content: "";
            position: absolute;
            top: 0px;
            left: 0rem;
            border-bottom: 1px solid #707070;
            width: 100%;
          }
        }

        span {
          position: relative;
          padding: 0 1rem;
          font-size: 0.75rem;
          // font-family: $sans-serif-bold;
          // margin: 0 0.5rem;
          &:nth-last-child(n + 2)::after {
            content: "|";
            position: absolute;
            right: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }

    .project-media {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: 1.5rem;
      .max-h-35 {
        max-height: 35rem;

        img {
          max-height: 35rem;
        }
      }
    }
  }
}

// .project-header:after {
//   content: "";
//   position: absolute;
//   bottom: 0px;
//   left: 0;
//   width: 50%;
//   border-bottom: 1px solid #0000cc;
// }
