@mixin line__animtion {
  &:hover {
    span {
      &::after {
        width: 100%;
      }
    }
  }

  span {
    position: relative;
    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      content: "";
      background: #ecebea;
      z-index: 1;
      height: 1px;
      width: 0px;
      -webkit-transition: width 0.5s ease-in-out;
      -moz-transition: width 0.5s ease-in-out;
      -o-transition: width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out;
      border-radius: 1px;
    }
  }
}

@mixin anchor__line_animation {
  color: $dark-grey;
  text-decoration: none;
  transition: color 0.3s ease-in;
  position: relative;
  &::after {
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    content: "";
    background: $dark-grey;
    z-index: 1;
    height: 1px;
    width: 0px;
    width: 100%;
    -webkit-transition: width 0.5s ease-in-out;
    -moz-transition: width 0.5s ease-in-out;
    -o-transition: width 0.5s ease-in-out;
    transition: width 0.5s ease-in-out;
    border-radius: 1px;
  }

  &:hover,
  &:focus {
    color: $magenta;
    text-decoration: none;

    &::after {
      width: 0px;
    }
  }
}
