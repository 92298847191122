.navbar-custom {
  position: fixed;
  top: 0;
  z-index: 40;
  overflow: hidden;
  background: $white;

  width: 100vw;

  -webkit-box-shadow: 0px 3px 6px #00000005;
  -moz-box-shadow: 0px 3px 6px #00000005;
  box-shadow: 0px 3px 6px #00000005;

  .navbar-top {
    min-height: $navbar-height-touch;
    display: flex;
    background-color: #ffffff;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    // box-shadow: inset 0 0 5px #f3f3f2;
    position: relative;
    z-index: 3;
    .nav-left {
      margin-left: 1rem;
      @include mobile {
        margin-left: 0rem;
      }

      display: flex;
      place-items: center;
      .logo {
        box-sizing: border-box;
        display: grid;
        place-items: center;
        width: 100%;
        margin: 0.5rem;

        img {
          min-width: 230px;
        }

        @include fullhd {
          margin: 0.75rem;
        }
      }

      .hamburger-button {
        background: transparent;
        border: none;
        display: grid;
        place-content: center;
        overflow: hidden;
        max-height: 60px;
        .hamburger {
          transition: translate 0.5s, rotate 0.5s;
        }
        .line {
          transition: 1s;
          stroke-dasharray: 60 31 60 300;
        }

        &[aria-expanded="true"] .hamburger {
          translate: 10px -5px;
          rotate: 0.125turn;
        }
        &[aria-expanded="true"] .line {
          stroke-dasharray: 60 105 60 300;
          stroke-dashoffset: -90;
        }
      }
    }

    .nav-right {
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 100%;
      // gap: 1rem;

      .text {
        padding: 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        a {
          position: unset;
        }
        &.last {
          margin-right: 2rem;
        }

        .active-lang {
          font-family: $sans-serif-bold;
          font-weight: normal;
          background: linear-gradient(
            to right,
            #0841db 0%,
            #ff0b98 50%,
            #c02733 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .de {
          margin-right: 2px;
        }
        .en {
          margin-left: 2px;
        }
        .line {
          margin-bottom: 1.5px;
        }
      }

      .contact {
        position: relative;
        overflow: hidden;
        z-index: 1; /* matters! */
        background-color: #f3f3f2;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 200%;
          background: linear-gradient(
            to right,
            #0841db 0%,
            #ff0b98 50%,
            #c02733 100%
          );
          background-size: 400% 400%;
          opacity: 0;
          transition: opacity 0.6s ease-in, transform 0.6s ease-out;
          z-index: -1;
          animation: animatedgradient 6s linear infinite;
        }

        &:hover::before {
          opacity: 1;
          transform: translateY(-50%);
        }

        display: grid;
        place-items: center;
        height: 4.375rem;

        a,
        .a {
          background-color: #f3f3f2;
          color: #d21f51;
          width: 14.375rem;
          height: 4.375rem;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          font-size: 0.875rem;
          transition: background 1s ease-out;
          background: linear-gradient(
            to right,
            #0841db 0%,
            #ff0b98 50%,
            #c02733 100%
          );

          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;

          cursor: pointer;

          &:hover {
            color: #ffffff;
            background-clip: text;
            -webkit-background-clip: none;
            -webkit-text-fill-color: #ffffff;
          }
        }
      }
    }
  }

  .navbar-bottom-wrapper {
    width: 100%;
    height: calc(100dvh - $navbar-height-touch);

    @include desktop {
      width: 100%;
      height: auto;
    }
  }

  .navbar-bottom {
    position: relative;
    background-color: #ffffff;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    width: 100%;

    @include touch {
      flex-direction: column;
      justify-content: space-between;
      padding: 0;
      overflow-y: scroll;
      height: 100%;
    }

    .text {
      padding: 0 1rem;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;

      &:first-child {
        margin-top: 3rem;
      }

      .active-lang {
        font-weight: normal;
        font-family: $sans-serif-bold;
        background: linear-gradient(
          to right,
          #0841db 0%,
          #ff0b98 50%,
          #c02733 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      a {
        text-decoration: none;
        color: #000;
      }
      .de {
        margin-right: 2px;
      }
      .en {
        margin-left: 2px;
      }
      .line {
        margin-bottom: 1.5px;
      }
    }

    .contact {
      overflow: hidden;
      z-index: 1; /* matters! */
      background-color: #f3f3f2;
      display: grid;
      place-items: center;
      width: 100vw;
      // position: absolute;
      bottom: 0;
      left: 0;

      a,
      .a {
        color: #d21f51;
        width: 14.375rem;
        height: 5.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        font-size: 0.875rem;
        transition: background 1s ease-out;
        background: linear-gradient(
          to right,
          #0841db 0%,
          #ff0b98 50%,
          #c02733 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    ul {
      margin: 0;
      display: grid;
      grid-auto-flow: row;
      width: auto;
      gap: 0rem;

      @include desktop {
        grid-auto-flow: column;
        width: 100vw;
      }

      li:last-of-type {
        margin: 0;
      }
      li {
        &::before {
          content: none;
        }
        cursor: pointer;
        position: relative;
        display: flex;
        //
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 5em;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;

        @media (min-width: 100px) and (max-width: 900px) {
          flex-basis: 2em;
          flex-grow: 0;
        }

        a {
          text-transform: uppercase !important;
          text-decoration: none;
          color: #363636;
          white-space: nowrap;
          font-size: 0.875rem;
          letter-spacing: 1px;
          padding: 1rem;
          width: 100%;
          text-align: center;
        }

        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          content: "";
          background: linear-gradient(to left, #743ad5, #d53a9d);
          z-index: 1;
          height: 2px;
          width: 0px;
          -webkit-transition: width 0.5s ease-in-out;
          -moz-transition: width 0.5s ease-in-out;
          -o-transition: width 0.5s ease-in-out;
          transition: width 0.5s ease-in-out;
          border-radius: 1px;
        }

        &:hover::after {
          background: linear-gradient(to left, #743ad5, #d53a9d);
          width: 100%;
        }
      }

      li.is-current {
        &::after {
          background: linear-gradient(to left, #743ad5, #d53a9d);
          width: 100%;
        }

        &:hover::after {
          background: linear-gradient(to left, #743ad5, #d53a9d);
          width: 0%;
        }

        // border-bottom: 10px solid;
        // border-image-slice: 1;
        // border-width: 5px;
        // border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
        // animation: animatedgradient 3s ease alternate infinite;
      }
    }
  }

  .text {
    a {
      text-decoration: none;
      color: $dark-grey;
    }
  }

  &.berlinfahrten {
    @include mobile {
      .navbar-top .nav-right .contact {
        a,
        .a {
          width: 6rem;
          .contact-title {
            display: none;
          }
        }
      }

      .navbar-top .nav-left .logo {
        margin-left: 1rem;
      }
    }
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// .navbar {
//   transition: all 250ms ease-in-out;
//   background: #ffffff;

//   .navbar-top {
//     display: flex;
//     justify-content: space-between;
//     width: 100%;
//   }

//   .navbar-brand {
//     padding: 0.65rem 0;
//     .menu-label {
//       display: flex;
//       align-items: center;
//       margin-left: auto;
//       margin-right: -0.75rem;
//       p {
//         color: $white;
//         padding-bottom: 0.25rem;
//       }
//     }
//     @include fullhd {
//       margin-left: 2.5rem;
//     }
//     .logo {
//       box-sizing: border-box;
//       display: grid;
//       place-items: center;
//       margin: 0.5rem;
//       @include fullhd {
//         margin: 0.75rem;
//       }

//       a {
//         > span {
//           min-width: 100%;
//           min-height: 100%;
//         }
//       }

//       img {
//         vertical-align: middle;
//       }
//     }
//   }

//   .navbar-burger {
//     color: $white;
//     height: 4rem;
//     padding: 0.5rem;
//     span {
//       height: 1px;
//     }
//     &:hover {
//       background-color: initial;
//     }
//     &.is-active {
//       color: $yellow;
//     }

//     @include tablet {
//       margin-left: 0;
//     }
//   }

//   .logo-text {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     * {
//       color: $white;
//       margin: 0;
//       padding: 0;
//     }
//     h3 {
//       font-size: 22px;
//       @include tablet {
//         font-size: 32px;
//       }
//     }
//     p {
//       font-size: 9px;
//       margin-top: -0.5rem;
//       @include tablet {
//         font-size: 14px;
//       }
//     }
//   }

//   .navbar-menu {
//     .right-end {
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       gap: 3.125rem;
//       .contact {
//         display: flex;
//         height: 100%;
//         background-color: #f3f3f2;

//         a {
//           background-color: #f3f3f2;

//           width: 14.375rem;
//           display: flex;
//           place-items: center;
//           justify-content: center;
//           text-decoration: none;
//           font-size: 0.875rem;
//           transition: background 1s ease-out;
//           background: linear-gradient(
//             to right,
//             #0841db 0%,
//             #ff0b98 50%,
//             #c02733 100%
//           );
//           -webkit-background-clip: text;
//           -webkit-text-fill-color: transparent;

//           &:hover {
//             background: linear-gradient(
//               to right,
//               #0841db 0%,
//               #ff0b98 50%,
//               #c02733 100%
//             );

//             color: #ffffff;
//             -webkit-background-clip: none;
//             -webkit-text-fill-color: #ffffff;
//           }
//         }
//       }
//     }

//     @include mobile {
//       .navbar-end {
//         height: calc(100vh - 4rem);
//         display: flex;
//         flex-direction: column;
//         justify-content: space-between;
//       }
//     }
//     .main-menu {
//       position: relative;
//       height: 100%;
//       list-style-type: none;
//       margin-top: 1rem;
//       @include mobile {
//         width: 75%;
//         margin: $spaceMD auto;
//       }
//       @include desktop {
//         margin: $spaceMD auto;
//         width: 35%;
//       }
//       @include tablet-only {
//         width: 50%;
//         margin: $spaceMD auto;
//         padding: 0 $spaceMD;
//       }
//       @include fullhd {
//         display: flex;
//         justify-content: center;
//         align-items: flex-start;
//         width: auto;
//         padding: 0;
//         margin-top: 0;
//         margin-bottom: 0;
//         margin-right: 1.5rem;
//       }
//       li {
//         position: relative;
//         text-transform: uppercase;
//         padding: 1rem 0 1rem;
//         * {
//           font-family: $sans-serif-bold;
//           letter-spacing: 2px !important;
//         }
//         @include fullhd {
//           display: flex;
//           align-items: center;
//           padding: 0;
//           margin-left: 1.75rem;
//           margin-top: 0.55rem;
//         }

//         &:last-of-type {
//           margin-bottom: 0;
//         }

//         &.has-sub {
//           position: relative;
//         }

//         .sub-title {
//           justify-content: space-between;
//           svg {
//             height: 0.75rem;
//             width: 0.75rem;
//             path {
//               fill: $white !important;
//             }
//             transform: rotate(90deg);
//             transition: transform 250ms ease-in-out;
//           }
//         }

//         &.is-active,
//         &:hover {
//           .sub-menu {
//             li {
//               &::after,
//               &::before {
//                 display: none;
//               }
//             }
//             @include fullhd {
//               display: flex;
//             }
//           }

//           > a,
//           > .a,
//           > a:focus,
//           > .a:focus {
//             color: $white;
//             transition: all 0.3s ease-in-out;
//             @include fullhd {
//               transform: scale(1.025);
//             }
//           }
//           > a.is-current {
//             color: $yellow;
//           }
//           > a:hover,
//           span:hover {
//             color: $yellow;
//           }

//           &.has-sub {
//             //to keep submenu open while moving cursor from menu item to sub menu
//             &:after {
//               content: "";
//               position: absolute;
//               height: 60px;
//               top: 100%;
//               width: 300%;
//               left: -100%;
//             }
//           }
//         }

//         &.is-active {
//           .sub-title {
//             svg {
//               transform: rotate(-90deg);
//             }
//           }
//           &.has-sub {
//             span {
//               &::after {
//                 transform-origin: center center;
//                 transform: rotate(-45deg);
//               }
//             }
//           }
//           .sub-menu {
//             transition: all 0.5s ease-in-out;
//             display: flex;
//           }
//         }

//         .sub-menu {
//           flex-direction: column;
//           transition: all 0.5s ease-in-out;
//           margin: 0;
//           display: none;
//           padding-top: 0.5rem;
//           padding-bottom: 1.5rem;
//           padding-left: 1.5rem;
//           padding-right: 1.5rem;
//           @include fullhd {
//             background-color: $red;
//             border: 1px solid $red;
//             position: absolute;
//             flex-direction: column;
//             left: 50%;
//             transform: translate(-50%, 0);
//             min-width: 300%;
//             text-align: center;
//             margin-top: 3rem;
//             @include widescreen {
//               margin-top: -2.75rem;
//             }
//             top: 0;

//             &::before {
//               position: absolute;
//               content: "";
//               top: -1rem;
//               left: calc(50% - 1rem);
//               transform: rotate(45deg);

//               display: block;
//               height: 2rem;
//               width: 2rem;
//               background: $red;
//             }
//           }
//           @include widescreen {
//             top: 7.5rem;
//           }

//           li {
//             margin-left: 0;
//             padding: 0.5rem;
//             justify-content: center;
//             &:last-of-type {
//               padding-bottom: 0;
//             }
//             list-style-type: none;
//           }
//         }
//       }
//       a,
//       .a {
//         z-index: 1;
//         color: $white;
//         text-decoration: none;
//         transition: all 0.1s ease-in-out;
//         letter-spacing: 0.2px;
//         cursor: pointer;
//         display: flex;
//         align-items: center;
//         height: 100%;

//         &.is-current {
//           color: $yellow;
//         }
//       }
//     }
//   }

//   @-webkit-keyframes change-bg {
//     0% {
//       background: transparent;
//     }
//     100% {
//       background: $red;
//     }
//   }
//   @keyframes change-bg {
//     0% {
//       background: transparent;
//     }
//     100% {
//       background: $red;
//     }
//   }
// }

// .navbar-down-menu {
//   margin-top: 4.299rem;
//   padding-top: 0;
//   padding-bottom: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #ffffff;
//   width: 100%;
//   ul {
//     margin: 0;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     gap: 2rem;
//     li:last-of-type {
//       margin: 0;
//     }
//     li {
//       &::before {
//         content: none;
//       }
//       display: inline-block;
//       padding: 1rem;
//       width: 100%;
//       a {
//         text-transform: uppercase !important;
//         text-decoration: none;
//         color: #ff0b98;
//       }
//     }

//     li.is-current {
//       border-bottom: 10px solid;
//       border-image-slice: 1;
//       border-width: 5px;
//       border-image-source: linear-gradient(to left, #743ad5, #d53a9d);
//     }
//   }
// }
