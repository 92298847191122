.wiki-page-header {
  padding: 0.5rem;

  @include tablet {
    padding: 2.25rem;
  }

  &.fullscreen {
    height: calc(100vh - $navbar-height-touch);
    @include desktop {
      height: calc(100vh - $navbar-height-desktop);
    }
  }

  &.halfscreen {
    min-height: 50vh;
  }

  &.is-single-page {
    &.fullscreen {
      height: calc(100vh - $navbar-height-touch);
    }
  }

  .headline-wrapper {
    @include desktop {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }

  .wiki-categories {
    justify-content: center;
    gap: 1rem;

    @include tablet {
      justify-content: space-around;
    }

    @include desktop {
      margin-top: 2rem;
    }

    .wiki-category {
      margin-bottom: 1.5rem;
      font-size: 0.875rem;
      padding: 0;

      &::after {
        display: none;
      }

      .count {
        font-family: $sans-serif-bold;
        font-size: 3.5rem;
        line-height: 3rem;
        position: relative;
        padding-bottom: 1rem;
        margin-bottom: 1rem;
        cursor: pointer;

        &::after {
          content: "";
          background: $white;
          width: 60%;
          height: 1px;
          bottom: 0;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      @include tablet-only {
        width: 33.333%;
      }
    }
  }

  &.has-white-bg {
    .frame-on {
      .border {
        border: 2px solid $light-grey;
      }

      &::after,
      &::before {
        background: $medium-grey;
      }

      .frame-edge {
        background: $medium-grey;
      }
    }
  }

  .frame {
    position: relative;
    height: 100%;

    .border {
      height: 100%;
    }

    &.frame-on {
      .border {
        border: 2px solid $white-50;
      }

      &::after,
      &::before {
        position: absolute;
        background: $white;
        content: " ";
        width: 80px;
        height: 80px;
      }

      &::after {
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
        transform: rotate(270deg);
      }

      &::before {
        top: 0;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      }

      .frame-edge {
        position: absolute;
        background: $white;
        width: 80px;
        height: 80px;

        &:nth-of-type(2) {
          bottom: 0;
          left: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(180deg);
        }

        &:nth-of-type(3) {
          bottom: 0;
          right: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(90deg);
        }
      }
    }
  }
}
