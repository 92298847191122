.text-info-box {
  .container {
    @include mobile_padding;
  }
  .overflow-hidden {
    overflow: hidden;
  }

  .main-content {
    @include tablet {
      padding: 2rem 1rem;
    }
    @include desktop {
      padding: 2rem;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .info-box {
    @include mobile {
      margin: 0 -30px;
      width: unset;
    }
  }
  .info {
    padding: 2rem;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .info-media-wrapper {
      display: flex;
      justify-content: center;
      padding: 1rem;
      .max-w-250 {
        max-width: 250px;
        width: 100%;
      }
    }
  }
}
