.image-slider-mod {
  padding: 1rem;

  &.has-white-bg {
    .image-element {
      background-color: #c1c1c11a;
    }
  }
  &.has-grey-bg {
    .image-element {
      color: #ffffff8c;
    }
  }

  &.has-gradient-bg {
    .image-element {
      color: #ffffff8c;
    }
  }

  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .image-element {
      color: #c1c1c10d;
    }
  }

  @include tablet {
    padding: $section-padding-tablet 0;
  }

  @include desktop {
    padding: $section-padding-desktop 0;
  }

  @include ultrahd {
    padding: $section-padding-ultrahd 0;
  }

  .swiper {
    overflow: visible;
  }

  .slider-wrapper {
    margin-top: 4rem;

    .embla-slider {
      @include reset-ul-li;
      .embla-container {
        display: flex;
        height: 100%;

        .embla-slide {
          margin-right: 1rem;
          min-width: 0;
          height: initial;
          flex: 0 0 100%;

          @include tablet {
            flex: 0 0 33.3333%;
          }

          @include widescreen {
            flex: 0 0 25%;
          }

          a {
            text-decoration: none;
          }
        }
      }
    }
  }

  .image-element {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1rem;
    position: relative;

    .image-link {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
    }

    .image-info-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;

      .image-wrapper {
        display: flex;
        aspect-ratio: 16 / 9;
      }

      .image-info {
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        min-height: 2rem;

        .overline {
          font-size: 0.75rem;
          line-height: 1.875rem;
        }

        .title {
          font-family: $sans-serif-bold;
        }
      }
    }
  }
}
