.recruiting-form {
  .form-headline {
    font-family: $sans-serif-bold;
  }

  .input-group,
  .date-input,
  .listbox {
    padding-bottom: 0.75rem;
  }

  .input-group {
    &.file {
      display: flex;
      border-bottom: 1px solid $dark-grey;
      position: relative;

      input {
        border-bottom: none;
      }

      label {
        width: 100%;
        bottom: 1px;
        height: 100%;
        top: auto;
        background: $white;
        font-size: 1rem;
        color: $dark-grey;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding-right: 0.5rem;
      }

      .download-icon {
        cursor: pointer;
        justify-self: flex-end;
        display: flex;
        align-items: flex-end;
        margin-right: 0.5rem;

        span {
          font-size: 1rem;
          background: $aunds-gradient;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: transparent;
          font-family: $sans-serif-book;
        }

        svg {
          margin-bottom: 0.25rem;
          margin-left: 0.25rem;
        }
      }

      .remove-btn {
        cursor: pointer;
        position: absolute;
        bottom: 0;
        right: 95px;
        background-color: $white;
      }

      .file-error {
        color: $red;
        position: absolute;
        bottom: -2rem;
        left: 0;
      }
    }

    label {
      color: $dark-grey;
    }
  }

  .success-message {
    &-headline {
      font-family: $sans-serif-bold;
    }

    .social {
      > div {
        column-gap: 4rem;
      }

      .social-text {
        p {
          padding-bottom: 0;
          font-family: $sans-serif-medium;
          font-size: 1.5rem;
        }
      }
    }
  }

  .loading-screen {
    position: fixed;
    top: 0;
    bottom: 0;
  }

  .date-input {
    .react-datepicker-wrapper {
      width: 100%;
      input:focus-within {
        outline: none;
      }
    }
  }
}
