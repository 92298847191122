.facts-module {
  .facts-headline {
    margin-bottom: 2.5rem;
    @include touch {
      margin-bottom: 1.75rem;
    }
  }
  h2,
  h3 {
    margin-bottom: 0;
  }
  .subline {
    display: block;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .facts-wrapper {
    @media (min-width: 768px) {
      padding-left: 3.5rem;
      border-left: 1px solid $dark-grey;
    }
    @include desktop {
      height: 100%;
    }
    .facts-title {
      font-family: $sans-serif-bold;
      font-size: 18px;
      line-height: 26px;
      display: block;
      margin-bottom: 1.25rem;
    }
    .facts {
      .fact {
        .fact-title {
          font-family: $sans-serif-bold;
          font-size: 1.75rem;
          line-height: 1.75rem;
          transition: background 1s ease-out;
          background: linear-gradient(
            to right,
            #0841db 0%,
            #ff0b98 50%,
            #c02733 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          @include tablet {
            font-size: 2rem;
            line-height: 2rem;
          }
        }
        &:not(:last-of-type) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
