.portrait-slider {
  .section {
    @include mobile {
      padding: 0;
    }
    .container {
      @include mobile_padding;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
  }

  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    * {
      color: $white;
    }
  }

  &.has-grey-bg {
    * {
      color: $dark-grey;
    }

    .portrait-slide {
      &.textmedia-slider {
        border: 1px solid $dark-grey !important;
      }
    }
  }

  .swiper-wrapper {
    box-sizing: border-box;
    .swiper-slide {
      height: auto;

      a {
        text-decoration: none;
      }
    }
  }

  .frame-box-wrapper {
    height: 100%;
    div:first-child {
      height: 100%;
    }
  }

  .portrait-slide {
    &.image-slider {
      position: relative;
      display: grid;
      overflow: hidden;
      color: $white;
      height: 100%;
      // &::before {
      //   position: absolute;
      //   content: "";
      //   top: 0;
      //   left: 0;
      //   width: 100%;
      //   height: 100%;
      //   z-index: 1;

      //   background: linear-gradient(
      //     180deg,
      //     transparent 80%,
      //     rgba(0, 0, 0, 0.6)
      //   );
      // }

      & > * {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      img {
        aspect-ratio: 7.5 / 12;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .portrait-content-wrapper {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1.5rem;

        .portrait-content {
          display: flex;
          align-items: flex-end;
          span {
            display: block;
          }
          button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            color: inherit;
          }
        }
      }
    }

    &.textmedia-slider {
      border: 1px solid $light-grey;
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 1.5rem;
      height: 100%;

      &:hover {
        * {
          color: $dark-grey;
        }

        path {
          stroke: $dark-grey !important;
        }
      }

      .media-wrapper {
        height: 90px;
        display: flex;
        margin: 0.625rem 0;
      }

      .portrait-content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1.5rem;
      }
    }

    .overline {
      line-height: 1;
    }

    &.video-slider {
      height: 100%;

      .inline-video {
        aspect-ratio: 7.5 / 12;
      }

      .react-player__preview {
        aspect-ratio: 7.5 / 12;
      }
    }

    .portfolio-slide {
      position: relative;
      display: grid;
      overflow: hidden;
      color: $white;
      height: 100%;

      .hover-content {
        position: absolute;
        height: 100%;
        width: 100%;
        opacity: 0;
        transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
        background-color: $white;
        color: $dark-grey;
        padding: 1.5rem;
        overflow: hidden;
        span {
          display: block;
        }

        display: flex;
        // flex-direction: column;
        // justify-content: flex-end;

        justify-content: center;
        align-items: center;

        .logo-wrapper {
          position: relative;
          width: 100%;
          padding-bottom: 53%;
          img {
            object-fit: contain;
            aspect-ratio: auto;
          }
        }

        .action-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 1rem;
          padding-top: 1rem;
          border-top: 1px solid $dark-grey;

          .logo {
            img {
              object-fit: contain;
              aspect-ratio: auto;
              height: 0.875rem;
            }
          }
        }
      }

      &::after,
      &::before {
        transition: opacity 0.3s ease-in, width 0.3s ease-in-out,
          height 0.3s ease-in-out;
        position: absolute;
        width: 0;
        height: 0;
        background: $aunds-gradient;
        content: " ";
        opacity: 0;
      }

      &::after {
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
        transform: rotate(270deg);
      }

      &::before {
        top: 0;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      }

      .corner-div {
        position: absolute;
        transition: opacity 0.3s ease-in, width 0.3s ease-in-out,
          height 0.3s ease-in-out;
        width: 0;
        height: 0;
        background: $aunds-gradient;

        &:nth-of-type(2) {
          bottom: 0;
          left: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(180deg);
        }

        &:nth-of-type(3) {
          bottom: 0;
          right: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(90deg);
        }
      }

      &:hover {
        @include desktop {
          background-color: $white;
          z-index: 1;
          &::after,
          &::before {
            opacity: 1;
            width: 80px;
            height: 80px;
            z-index: 2;
            @include desktop {
              width: 60px;
              height: 60px;
            }
          }

          .corner-div {
            opacity: 1;
            width: 80px;
            height: 80px;
            z-index: 2;

            @include desktop {
              width: 60px;
              height: 60px;
            }
          }

          .hover-content {
            opacity: 1;
            z-index: 1;
          }
        }
      }
      & > * {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
      }

      img {
        aspect-ratio: 7.5 / 12;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .portrait-content-wrapper {
        z-index: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1.5rem;
        background: transparent
          linear-gradient(
            0deg,
            rgba(51, 51, 51, 0.7) 0%,
            rgba(118, 118, 118, 0) 40%,
            rgba(118, 118, 118, 0) 100%
          )
          0% 0% no-repeat padding-box;

        .portrait-content {
          display: flex;
          align-items: flex-end;
          * {
            color: $white;
          }
          span {
            display: block;
          }

          button {
            background-color: transparent;
            border: none;
            cursor: pointer;
            color: inherit;
          }
        }
      }
    }
  }

  .portrait-slider-navigations {
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    width: 100%;
    padding: 1rem 0 0;

    display: grid;
    grid-template-columns: 1fr 1fr;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 0;
      width: 100%;
    }

    .next,
    .prev {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: inherit;
    }
  }

  .portrait-slider-swiper-wrapper {
    width: calc(100vw - ((100vw - 100%) / 2));

    .embla-slider {
      @include reset-ul-li;
      overflow: hidden;
      .embla-container {
        display: flex;
        height: 100%;

        .embla-slide {
          margin-right: 1.25rem;
          min-width: 0;
          height: 100%;
          flex: 0 0 70%;

          @include tablet {
            flex: 0 0 33.33%;
          }

          @include widescreen {
            flex: 0 0 20%;
          }
        }
      }
    }
  }
}

.portrait-slider-module {
  .portrait-slider {
    .section {
      padding: 0;
    }
  }
}
.portrait-slider-header {
  .section:first-of-type {
    .container {
      @include mobile_padding;
    }
  }
}

.portrait-cta-slide {
  aspect-ratio: 7.5 / 12;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  .portrait-cta-info-wrapper {
    .portrait-cta-headline {
      display: inline-block;
      font-family: $sans-serif-bold;
    }

    .portrait-cta-text {
      display: -webkit-box;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .portrait-cta-button {
      margin-top: 2rem;
    }
  }
}
