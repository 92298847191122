@charset "utf-8";
//own variables
@import "./styles/global-vars";
// bulma mixinss, extends and initial variables
@import "../node_modules/bulma/sass/utilities/_all.sass";
@import "../node_modules/bulma/sass/base/_all.sass";
@import "../node_modules/bulma/sass/helpers/_all.sass";
// import modules you need
@import "../node_modules/bulma/sass/grid/_all.sass";
// @import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/components/navbar.sass";
@import "../node_modules/bulma/sass/components/menu.sass";
@import "../node_modules/bulma/sass/layout/hero.sass";
@import "../node_modules/bulma/sass/layout/section.sass";
@import "../node_modules/bulma/sass/elements/image.sass";
@import "../node_modules/bulma/sass/components/modal.sass";
//@import "../node_modules/bulma/sass/components/card.sass";

@import "./styles/resets";

// Glossary Tooltip
@import "../node_modules/react-tooltip/dist/react-tooltip.css";

@import "./styles/extraMixinis";

// Eigene Komponeneten
@import "./styles/lineAnimation";
@import "./styles/main";
@import "./styles/navbar";
@import "./styles/textMedia";
@import "./styles/media";
@import "./styles/socials";
@import "./styles/slider";
@import "./styles/listbox";
@import "./styles/footer";
@import "./styles/autorHeader";
@import "./styles/insightHeader";
@import "./styles/projectHeader";
@import "./styles/breadcrumbs";
@import "./styles/divider";
@import "./styles/wikiHeader";
@import "./styles/wiki";
@import "./styles/text";
@import "./styles/cta";
@import "./styles/promoBanner";
@import "./styles/autoren";
@import "./styles/textMediaGrid";
@import "./styles/seiteheader";
@import "./styles/ctaBox";
@import "./styles/portraitSlider";
@import "./styles/textInfoBox";
@import "./styles/joinusHeader";
@import "./styles/featureBox";
@import "./styles/jobs";
@import "./styles/insights";
@import "./styles/inlineNav";
@import "./styles/accordion";
@import "./styles/headerInsights";
@import "./styles/bannerJob";
@import "./styles/logos";
@import "./styles/carousel";
@import "./styles/videoSlider";
@import "./styles/teams";
@import "./styles/form";
@import "./styles/portfolioGrid";
@import "./styles/loader";
@import "./styles/search";
@import "./styles/seopage";
@import "./styles/recruitingForm";
@import "./styles/imageSlider";
@import "./styles/odometer";
@import "./styles/portfolioSlider";
@import "./styles/pricing";
@import "./styles/insightBlogcast";
@import "./styles/hero";
@import "./styles/mouseFollow";
@import "./styles/scrollAnimation";
@import "./styles/awards";
@import "./styles/scrollDown";
@import "./styles/parallaxText";
@import "./styles/youtubeLite";
@import "./styles/tooltip";
@import "./styles/loadmorePagination";
@import "./styles/404";
@import "./styles/animationTooltip";
@import "./styles/facts";

// Consent Management
@import "../node_modules/@aleksundshantu.com/aunds-cookie-plugin-react/dist/esm/index";

// Template
@import "./styles/template";
