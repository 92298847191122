.feature-box {
  a {
    text-decoration: none;
    color: inherit;
  }

  &.has-white-bg {
    .grid-box {
      border: 2px solid $light-grey;
    }
  }
  &.has-grey-bg,
  &.has-dark-grey-bg,
  &.has-darkgrey-bg {
    .grid-box {
      border: 1px solid $grey;
    }
  }

  .feature-title {
    font-family: $sans-serif-bold;
    font-size: 1.5rem;
    line-height: 1.875rem;
  }

  .arrow {
    transition: transform 0.3s ease-in;
  }

  .grid-box {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1.5rem;
    background-color: inherit;
    transition: background-color 0.3s ease-in;
    height: 100%;

    .arrow {
      height: 100%;
      display: flex;
      align-items: flex-end;
    }
  }
}

.feature-box-slider-swiper-wrapper {
  @include desktop {
    width: calc(100vw - ((100vw - 100%) / 2));
  }
}

.feature-box-slider {
  &.full-width {
    padding: 1rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .embla-slider {
    @include reset-ul-li;
    overflow: hidden;
    .embla-container {
      display: flex;
      height: 100%;

      .embla-slide {
        margin-right: 1.25rem;
        min-width: 0;
        height: auto;
        flex: 0 0 100%;

        @include desktop {
          flex: 0 0 50%;
        }

        @include widescreen {
          flex: 0 0 33.33%;
        }

        @include fullhd {
          flex: 0 0 30%;
        }
      }
    }
  }

  &.has-white-bg {
    .feature-box-slide {
      border: 2px solid $light-grey;
    }
  }
  &.has-grey-bg,
  &.has-dark-grey-bg,
  &.has-darkgrey-bg {
    .feature-box-slide {
      border: 2px solid $white;
    }
  }

  .feature-box-slide {
    padding: 3.5rem 1.5rem;
    border: 2px solid $white;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    gap: 1.5rem;
    height: 100%;

    @include tablet {
      justify-content: space-between;
      flex-direction: row;
      gap: 2rem;
    }

    .content-wrapper,
    .media-wrapper {
      display: flex;
    }

    .media-wrapper {
      justify-content: center;

      @include tablet {
        justify-content: flex-start;
      }
    }

    .content-wrapper {
      flex-direction: column;
      gap: 1.5rem;
      justify-content: space-between;

      @include tablet {
        justify-content: space-between;
      }

      .feature-title {
        font-size: 1.5rem;
        line-height: 1.875rem;
        font-family: $sans-serif-medium;
      }
    }
  }
}

.feature-box,
.feature-box-slider {
  .frame-box-wrapper {
    height: 100%;

    & > div:first-child {
      height: 100%;
    }
  }

  .media-wrapper {
    display: flex;
    .media-container {
      height: 150px;
      width: 150px;
      object-fit: contain;
    }
  }
}

.feature-box-slider-navigations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.5rem;
  padding-bottom: 80px;
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 1rem;
  }

  .next,
  .next {
    display: grid;
    place-items: center;
  }

  .prev {
    transform: rotate(180deg);
  }
}
