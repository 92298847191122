.template {
  min-height: 100vh;
  background: linear-gradient(
    to right,
    rgba(8, 65, 219, 0.15) 0%,
    rgba(255, 11, 152, 0.15) 50%,
    rgba(192, 39, 51, 0.15) 100%
  );
  .template-head {
    text-align: center;
    margin-bottom: $spaceMD;

    .template-title {
      background: #0841db;
      background: linear-gradient(
        to right,
        #0841db 0%,
        #ff0b98 50%,
        #c02733 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-bottom: 0;
    }
  }

  .template-block {
    text-align: center;
    cursor: pointer;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 1);
    transition: all 250ms ease-in-out;

    &:hover {
      box-shadow: 0 0 0 1px rgba(255, 11, 152, 0.75);
      background-color: rgba(255, 11, 152, 0.25);
    }

    padding: 5rem;
  }
}
.template {
  &-info {
    background: rgba(8, 65, 219, 0.5);
    border: 1px solid rgb(8, 65, 219);
    border-radius: 10px;
    padding: 1.5rem;
    color: $white;
    * {
      color: $white;
    }
    margin-bottom: $spaceSM;
  }
  &-error {
    background: rgba(192, 39, 51, 0.5);
    border: 1px solid rgb(192, 39, 51);
    border-radius: 10px;
    padding: 1.5rem;
    color: $white;
    * {
      color: $white;
    }
    margin-bottom: $spaceSM;
  }
  &-success {
    background: rgba(66, 156, 84, 0.5);
    border: 1px solid rgb(66, 156, 84);
    border-radius: 10px;
    padding: 1.5rem;
    color: $white;
    * {
      color: $white;
    }
    margin-bottom: $spaceSM;
  }
}
