.insight-header {
  &.has-white-bg,
  &.has-grey-bg {
    svg {
      fill: #444;
    }
  }

  &.has-gradient-bg,
  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    svg {
      fill: $white;
    }
  }

  .section .container {
    @include mobile_padding;
  }
  .header-info {
    .header-animtion {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      .bg-text {
        line-height: 1.5;
        color: $light-grey;
        text-align: center;
        font-size: 3.75rem;
        @include tablet {
          font-size: 9.375rem;
        }
      }

      .subline {
        position: absolute;
        top: calc(50% - 1rem);
        bottom: calc(50% - 1rem);
        left: 0;
        display: flex;
        align-items: center;
        width: 100%;
        flex: 1 1 auto;
        .line-wrapper {
          overflow: hidden;
          display: flex;
          flex: 1 1 auto;
          margin: 0 0.5rem;
          .line {
            content: "";
            flex: 1 1 auto;
            border-top: 1px solid $dark-grey;
          }
        }
      }
    }

    .content-wrapper {
      .title-wrapper {
        h1 {
          margin-bottom: 1.5rem;
        }
      }

      .post-wrapper {
        .author-info {
          margin-bottom: 1.5rem;
        }

        .post-description {
          & ul:first-child {
            margin-top: 0;
          }
        }
      }

      .author-info {
        display: grid;

        @include tablet {
          align-items: center;
          justify-content: center;
          grid-template-columns: 0.5fr 3fr;
        }

        .author-image-wrapper {
          img {
            transition: transform 0.3s cubic-bezier(0.37, 0, 0.63, 1);
          }

          &:hover {
            img {
              transform: scale(1.1);
              aspect-ratio: 1 / 1;
            }
          }

          display: grid;
          @include tablet {
            place-items: center;
          }
          .is-107x107 {
            width: 107px;
            height: 107px;
            border-radius: 50%;
            overflow: hidden;
          }
        }

        .post-info {
          margin: 1rem 0;
          @include tablet {
            margin: 0 1.8rem;
          }

          display: flex;
          flex-direction: column;
          justify-content: center;
          height: 100%;
          gap: 0.5rem;

          .post-dates {
            span {
              padding-right: 0.6rem;
              &.updated-date {
                position: relative;
                font-family: $sans-serif-bold;
                // color: $red;
                padding: 0 0.6rem;
                &::before {
                  font-family: $sans-serif-book;
                  content: "|";
                  position: absolute;
                  left: 0;
                  color: $dark-grey;
                }
              }
            }
          }

          .post-socials {
            display: flex;
            align-items: center;

            & span:first-child {
              padding: 0;
            }

            span {
              padding-left: 0.875rem;

              svg {
                width: 20px;
              }
              a {
                display: grid;
                place-items: center;
              }
            }
          }
        }
      }
    }
  }

  padding: 1rem 0 0;

  .insight-navigation {
    h2 {
      strong {
        color: $dark-grey;
      }
    }

    .navigation-link {
      position: relative;
      text-decoration: underline;
      text-underline-offset: 4px;
      color: $dark-grey;

      &::after {
        content: none;
      }
    }

    .a {
      cursor: pointer;
    }

    .navigation-wrapper {
      @include desktop {
        columns: 3;
      }
    }
  }
}
