@mixin ul-reset {
  margin: 0;
  > li {
    padding-left: 0;
    padding-bottom: 0;

    &::before {
      content: unset;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    a {
      &:after {
        content: unset;
      }
    }
  }
}
