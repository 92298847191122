.slider {
  // Sample styling .slide-item

  .slide-item {
    text-align: center;
    background-color: rgba(8, 65, 219, 0.25);
    border-radius: 10px;
    div {
      padding: 3rem;
    }
  }

  .swiper {
    width: 100%;
  }
  .swiper-wrapper {
    height: fit-content;
  }

  .swiper-slide,
  .swiper-slide {
    height: auto !important;
  }

  .swiper-pagination {
    bottom: 0 !important;
    > .swiper-pagination-bullet {
      opacity: 1;
      border: $grey solid 1px;
      background-color: $grey;
    }

    > .swiper-pagination-bullet-active {
      border: $dark-grey solid 1px;
      background-color: $dark-grey;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    height: 1.5rem;
    width: 0.8rem;

    svg {
      width: 100%;
    }

    &::after,
    &::before {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 0rem;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1);
    -ms-filter: fliph; /*IE*/
    filter: fliph; /*IE*/
    margin-left: $spaceXS;
  }

  .swiper-button-next {
    right: 0rem;
    margin-right: $spaceXS;
  }
}
