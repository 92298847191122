.animationslider {
  &.fullscreen {
    min-height: calc(100vh - $navbar-height-touch);
    @include tablet {
      height: calc(100vh - $navbar-height-touch);
    }
    @include desktop {
      height: calc(100vh - $navbar-height-desktop);
    }
  }

  &.halfscreen {
    min-height: 50vh;
  }

  &.is-single-page {
    &.fullscreen {
      min-height: calc(100vh - $navbar-height-touch);

      @include tablet {
        height: calc(100vh - $navbar-height-touch);
      }

      @include desktop {
        height: calc(100vh - $navbar-height-desktop);
      }
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: center;

  @include tablet {
    height: 100%;
  }

  *,
  .btn {
    color: $white;

    &:hover {
      color: $white;
    }
  }

  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  .slider-title {
    font-size: 3.75rem;
    font-family: $sans-serif-bold;
    line-height: 1;
    display: inline-block;
    text-align: center;
  }

  .animation {
    display: grid;
    grid-template-rows: 60dvh 1fr;
    @include tablet {
      grid-template-rows: 2fr 1fr;
    }

    gap: 1.5rem;
    height: 100%;
  }

  .slider-bar {
    width: 100%;
    display: flex;
    justify-content: center;

    .prev,
    .next {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content {
      text-align: center;
      transition: max-height 1s ease-in-out;
      @include tablet {
        text-align: left;
      }
    }
  }

  .content-wrapper {
    position: relative;
    @include mobile {
      padding-bottom: 315px;
    }

    @include tablet {
      padding-bottom: 53%;
    }

    .content-inner {
      position: absolute;
      width: 75%;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding: 1rem 1rem 0;

      @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 100%;
      }
    }
  }
}
