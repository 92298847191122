.searchbar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
  }

  .h3 {
    min-width: fit-content;
    font-family: $sans-serif-bold;
    display: inline-block;
    margin-right: 1rem;

    @include desktop {
      margin-bottom: 0;
    }
  }

  .searchbar-container {
    width: 100%;
    display: flex;
    border-bottom: 1px solid $black;

    @include desktop {
      width: 80%;
      position: relative;
      top: -8px;
    }

    input {
      border-bottom: none;
    }

    .searchbar-icon {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      svg {
        width: 1.5rem;
        height: auto;
        padding-bottom: 0.25rem;
      }
    }
  }
}

.search-info {
  span {
    display: block;
    font-family: $sans-serif-bold;

    @include mobile {
      text-align: center;
    }
  }
}
