@mixin reset-ul-li {
  ul {
    margin: 0;
    li {
      padding: 0;
      margin: 0;
      &::before {
        content: none;
      }

      a {
        &::after {
          content: none !important;
        }
      }
    }
  }
}
