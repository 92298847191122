.divider {
  position: relative;

  // Horizontal Slice Top
  // clip-path: inset(-500% 0% 50% 0%);
  // Horizontal Slice Bottom
  // clip-path: inset(50% 0% -500% 0%);
  &.has-white-bg {
    .section-bg-text,
    .divider-background-title {
      color: #c1c1c11a;
    }
  }
  &.has-grey-bg {
    p {
      color: $text;
    }
    .section-bg-text,
    .divider-background-title {
      color: #ffffff8c;
    }
  }
  &.has-gradient-bg {
    p {
      color: $white;
    }
    .section-bg-text,
    .divider-background-title {
      color: #ffffff8c;
    }
    .divider .divider-row .divider-body .line {
      background-color: $medium-grey;
    }
  }
  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    p {
      color: $white;
    }
    .section-bg-text,
    .divider-background-title {
      color: #c1c1c10d;
    }
    .divider .divider-row .divider-body .line {
      background-color: $grey;
    }
  }
  &.animation-active {
    margin-bottom: 1rem;
    @include tablet {
      margin-bottom: 3rem;
    }

    .divider-background-title {
      position: absolute;
      top: unset;
    }
  }
  .divider-row {
    position: relative;
    @include tablet {
      min-height: 11vw;
    }

    .divider-head {
      @include tablet {
        margin-left: 50%;
      }

      h2,
      h3 {
        @include fullhd {
          margin-bottom: $spaceSM;
        }
        max-width: 26.875rem; // 430px
      }
    }

    .divider-body {
      display: grid;
      align-items: center;
      grid-template-columns: auto 1fr;

      .divider-content {
        @include tablet {
          white-space: nowrap;
        }
        z-index: 1;
      }

      .line {
        height: 1px;
        width: 100%;
        border-radius: 10px;
        margin-left: 0.5rem;
        background-color: $text;
        @include mobile {
          margin-left: 5px;
        }
      }

      .divider-background-title {
        position: absolute;
        top: 50%;
        text-transform: uppercase;
        white-space: nowrap;

        &.upper-part {
          clip-path: inset(-500% 0% 50% 0%);
        }
        &.lower-part {
          clip-path: inset(50% 0% -500% 0%);
        }

        font-family: $sans-serif-black;
        // font-size: 7vw;
        font-size: 10vw;
        line-height: 1;
        @include fullhd {
          font-size: 11vw;
        }
        opacity: 0.25;
        margin: 0 auto;
        padding: 0;
      }
    }
  }

  .h-in-divider {
    @include desktop {
      position: absolute;
      width: 100%;
      z-index: 100;
      margin: 0;
      padding: 0 1rem;
    }
  }
}

.absolute-divider {
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 4;

  .bg-text-wrapper {
    position: relative;
    height: 100%;
  }

  .divider-background-title {
    position: absolute;
    top: 0;
    text-transform: uppercase;
    white-space: nowrap;
    left: 0;
    right: 0;
    text-align: center;

    color: rgba(0, 0, 0, 1);

    &.upper-part {
      clip-path: inset(-500% 0% 50% 0%);
    }
    &.lower-part {
      clip-path: inset(50% 0% -500% 0%);
    }

    font-family: $sans-serif-black;
    // font-size: 7vw;
    font-size: 10vw;
    line-height: 1;
    @include fullhd {
      font-size: 11vw;
    }
    margin: 0 auto;
    padding: 0;
  }
}

.section-bg-text {
  position: fixed;
  text-transform: uppercase;
  white-space: nowrap;
  color: #c1c1c11a;
  font-family: $sans-serif-black;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 10vw;
  top: 23%;
  line-height: 1;

  z-index: -1;
  @include fullhd {
    font-size: 11vw;
  }
  &.upper-part {
    clip-path: inset(-500% 0% 50% 0%);
  }
  &.lower-part {
    clip-path: inset(50% 0% -500% 0%);
  }

  margin: 0 auto;
  padding: 0;
}

// section {
//   border: 1px solid;
// }
