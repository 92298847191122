.text-media-header {
  &.fullscreen {
    height: calc(100vh - $navbar-height-touch);

    @include desktop {
      height: calc(100vh - $navbar-height-desktop);
    }
  }

  // &.halfscreen {
  //   // .container,
  //   // .columns,
  //   // .column {
  //   //   height: 100%;
  //   // }
  // }

  &.is-single-page {
    &.fullscreen {
      height: calc(100vh - $navbar-height-touch);
    }
  }

  .media-wrapper {
    aspect-ratio: 16 / 9;

    .image-wrapper {
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
