.text-media-grid {
  &.has-white-bg {
    .with-line {
      &.two-columns {
        @include widescreen {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $grey;
          }
        }
      }
      &.three-columns {
        @include tablet-only {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $grey;
          }
        }

        @include desktop-only {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $grey;
          }
        }

        @include widescreen {
          .grid-element:nth-child(3n + 2) {
            border-right: 1px solid $grey;
            border-left: 1px solid $grey;
          }
        }
      }
    }
  }

  &.has-grey-bg {
    .number-element .element-number {
      color: $medium-grey;
    }
    .with-line {
      &.two-columns {
        @include widescreen {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $white;
          }
        }
      }
      &.three-columns {
        @include tablet-only {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $medium-grey;
          }
        }

        @include desktop-only {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $medium-grey;
          }
        }

        @include widescreen {
          .grid-element:nth-child(3n + 2) {
            border-right: 1px solid $medium-grey;
            border-left: 1px solid $medium-grey;
          }
        }
      }
    }
  }

  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .with-line {
      &.two-columns {
        @include widescreen {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $white;
          }
        }
      }
      &.three-columns {
        @include tablet-only {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $white;
          }
        }

        @include desktop-only {
          .grid-element:nth-child(odd) {
            border-right: 1px solid $white;
          }
        }

        @include widescreen {
          .grid-element:nth-child(3n + 2) {
            border-right: 1px solid $white;
            border-left: 1px solid $white;
          }
        }
      }
    }
  }

  .media-text-element,
  .number-element,
  .text-element {
    @include tablet {
      padding: 0 2rem;
    }

    .element-title {
      font-size: 1.375rem;
      font-family: $sans-serif-bold;
      line-height: 1.875rem;
      margin-bottom: 1rem;
      display: inline-block;
    }
  }

  .number-element {
    .element-number {
      font-size: 5.625rem;
      @include tablet {
        font-size: clamp(4rem, 6vw, 5.625rem);
      }

      font-family: $sans-serif-bold;
      color: $light-grey;
      display: inline-flex;
      line-height: 0.95;
    }
  }

  .media-text-element {
    .image-caption-grid {
      padding-top: 0;
      .element-title {
        margin-bottom: 0;
      }
    }

    .media-wrapper {
      display: flex;
      object-fit: contain;

      .animation-player {
        max-height: 200px;
        max-width: 200px;
      }

      object {
        max-width: 180px;
      }
    }
  }
}
