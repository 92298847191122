.startseite-header {
  padding-top: 0;
  .text-animation-wrapper {
    @include touch {
      height: calc(100vh - $navbar-height-touch);
      height: calc(100dvh - $navbar-height-touch);
    }

    height: calc(100vh - $navbar-height-desktop);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 1rem;
  }

  .container {
    // padding-top: 0;
    // height: 100%;

    // @include ultrahd {
    //   max-width: 100% !important;
    // }
  }

  .position-one,
  .position-two,
  .position-three {
    overflow: hidden;
    &:hover {
      .text-big {
        animation: gradient__text__ultrahd 0.5s
          cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
      }
    }
  }

  .position-one {
    text-align: left;

    @include tablet-only {
      display: flex;
      align-items: flex-end;
    }
  }

  .position-two {
    text-align: right;

    @include touch {
      padding-top: 0;
    }
  }

  .position-three {
    text-align: left;
    padding-top: 0;
  }

  .slogan-wrapper {
    padding-top: 0;
  }

  .text-big {
    position: relative;
    display: inline-block;
    font-size: 3.25rem;
    z-index: 35;
    @include tablet {
      font-size: clamp(5.5rem, 6vw, 6.5rem);
    }
    @include fullhd {
      font-size: 8vw;
    }

    cursor: none;
    line-height: 1;
    text-align: center;
    font-family: $sans-serif-bold;

    // inverted color of orginal gradient
    background-image: linear-gradient(
      102deg,
      #e9bd00 0%,
      #00cd5d 52%,
      #00cdbf 100%
    );

    background-size: cover;

    mix-blend-mode: difference;
    background-color: white;

    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

    background-position: 0 7rem;
    animation: black__text 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;

    @include fullhd {
      background-position: 0 8vw;
      animation: black__text__ultrahd 0.5s
        cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
    }

    &.is-active {
      animation: gradient__text 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
        forwards;
      background-color: white;

      @include fullhd {
        animation: gradient__text__ultrahd 0.5s
          cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
      }
    }
  }

  .slogan {
    font-size: 1.5rem;
    font-family: $sans-serif-light;
    line-height: 1;
    text-align: left;
    strong {
      font-family: $sans-serif-bold;
    }
  }

  .mobile-video-wrapper {
    position: relative;
    width: 269px;
    height: 269px;

    .player-wrapper {
      top: 0;
      bottom: 0;
    }

    .hero-poster {
      border-radius: 50%;
      z-index: 1;
      transform: scale(0.99);
    }
  }

  .tablet-video-wrapper {
    position: relative;
    width: 269px;
    height: 269px;

    .player-wrapper {
      top: 0;
      bottom: 0;
    }

    .hero-poster {
      border-radius: 50%;
      z-index: 1;
    }
  }

  .player-wrapper {
    max-width: 269px;
    max-height: 269px;

    position: absolute;
    aspect-ratio: 1 / 1;
    bottom: 1rem;
    border-radius: 50%;
    overflow: hidden;
    z-index: 3;

    video {
      object-fit: cover;
    }
  }

  .divider {
    margin-bottom: 0;
  }

  @keyframes gradient__text__ultrahd {
    0% {
      background-position: 0 8vw;
    }
    10% {
      background-position: 0 7vw;
    }
    20% {
      background-position: 0 6vw;
    }
    30% {
      background-position: 0 5vw;
    }
    40% {
      background-position: 0 4vw;
    }
    50% {
      background-position: 0 3vw;
    }
    60% {
      background-position: 0 2vw;
    }
    80% {
      background-position: 0 1vw;
    }
    100% {
      background-position: 0 0;
    }
  }

  @keyframes black__text__ultrahd {
    0% {
      background-position: 0 0;
    }
    10% {
      background-position: 0 1vw;
    }
    20% {
      background-position: 0 2vw;
    }
    30% {
      background-position: 0 3vw;
    }
    40% {
      background-position: 0 4vw;
    }
    50% {
      background-position: 0 5vw;
    }
    60% {
      background-position: 0 6vw;
    }
    80% {
      background-position: 0 7vw;
    }
    100% {
      background-position: 0 8vw;
    }
  }

  @keyframes gradient__text {
    0% {
      background-position: 0 7rem;
    }
    10% {
      background-position: 0 6rem;
    }
    20% {
      background-position: 0 5rem;
    }
    30% {
      background-position: 0 4rem;
    }
    40% {
      background-position: 0 3rem;
    }
    50% {
      background-position: 0 2rem;
    }
    60% {
      background-position: 0 1rem;
    }
    100% {
      background-position: 0 0;
    }
  }

  @keyframes black__text {
    0% {
      background-position: 0 0;
    }
    10% {
      background-position: 0 1rem;
    }
    20% {
      background-position: 0 2rem;
    }
    30% {
      background-position: 0 3rem;
    }
    40% {
      background-position: 0 4rem;
    }
    50% {
      background-position: 0 5rem;
    }
    60% {
      background-position: 0 6rem;
    }
    100% {
      background-position: 0 7rem;
    }
  }
}
