.teams-mod {
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    position: relative;

    .image-wrapper {
      position: absolute;
      inset: 0;
    }
  }
}
