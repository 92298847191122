@mixin smallscreen {
  @media screen and (max-width: 965px) {
    @content;
  }
}

.pricing-module {
  .pricing-elements-wrapper {
    @include smallscreen {
      flex-direction: column;
    }
  }

  .pricing-box {
    position: relative;
    min-height: 367px;
    background-color: #f3f3f2;
    padding: 1.875rem;
    cursor: pointer;

    @include smallscreen {
      width: 100%;
    }

    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);

    .title {
      color: $purple;
      text-align: center;
      display: block;
      margin-bottom: 0;
    }

    .price-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }

    .price {
      font-size: 3rem;
      color: $dark-grey;
      line-height: 2rem;
      font-family: $sans-serif-bold;
      position: relative;

      &::before {
        content: "\20AC";
        position: absolute;
        top: 0;
        right: -1.5rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
      }
    }

    .price-subline {
      font-size: 1.25rem;
      color: $dark-grey;
      line-height: 1.875rem;
    }

    .action-wrapper {
      display: flex;
      flex-direction: column;
      gap: 34px;
      align-items: center;

      .price-info {
        font-size: 0.875rem;
        color: $dark-grey;
        line-height: 1.5rem;
        text-align: center;
      }
    }

    .btn.is-primary {
      border: 1px solid #ffff;
      color: #333333;
      line-height: 20px;
      text-align: center;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      height: 6.3%;
      left: 0;
      width: 100%;
      transition: all 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      background-color: #f3f3f2;
      z-index: 1;

      @include smallscreen {
        content: none;
      }
    }

    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }

    &.is-highlighted {
      margin: 0;
      background-color: $purple;
      box-shadow: 0px 3px 6px #00000029;

      &::before,
      &::after {
        background-color: $purple;
      }

      &::before {
        top: -6.3%;
      }
      &::after {
        bottom: -6.3%;
      }

      .title {
        color: $white;
      }

      .btn.is-primary {
        color: #ffff;
      }

      .price {
        color: $white;
      }

      .price-subline {
        color: $white;
      }

      .price-info {
        font-size: 0.875rem;
        color: $white;
        line-height: 1.5rem;
        text-align: center;
        * {
          color: $white;
        }
      }
    }

    &.is-not-highlighted {
      min-height: 60px;
    }
  }

  .accordion-column {
    @include desktop {
      padding: 0;
    }
  }

  .accordion-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: 2.688rem;

    .general-info {
      p {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: #8f8e8e;

        &::first-line {
          font-size: 18px;
          color: #333333;
        }
      }
    }

    .accordion {
      @include desktop {
        border-top: 2px solid #f3f3f2;
      }

      .accordion-item {
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        .accordion-title {
          font-size: 1.25rem;
          line-height: 1.5rem;

          @include desktop {
            font-size: 1.5rem;
            line-height: 2rem;
          }
        }

        .icon-wrapper {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .pricing-promobanner {
    background-color: #333333;
    color: $white;
    @include touch {
      padding: 2rem;
    }

    padding: 3rem;

    .svg-wrapper {
      max-width: 126px;
      max-height: 126px;
      height: 100%;
      width: 100%;
    }

    .text-wrapper {
      @include touch {
        text-align: center;
      }
      .title {
        @include touch {
          font-size: 1.875rem;
          line-height: 2rem;
        }
        font-size: 2rem;
        line-height: 1;
        font-family: $sans-serif-bold;
        color: $white;
        margin-bottom: 0.8rem;
        display: inline-block;
      }

      .content {
        * {
          @include touch {
            font-size: 1rem;
            line-height: 1.5rem;
          }
          font-size: 1.25rem;
          line-height: 2rem;
        }

        p {
          color: $white;

          &:first-child {
            padding-bottom: 0;
          }
        }

        span {
          color: #ff71f2;
          font-family: $sans-serif-bold;
        }
      }
    }

    .action-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      @include desktop {
        justify-content: flex-end;
        align-items: flex-end;
      }

      .btn.is-primary {
        border: 1px solid #ffff;
        color: #ffff;
        line-height: 20px;
        text-align: center;
        margin-bottom: 1rem;
      }
    }
  }
}
