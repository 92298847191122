.listbox {
  .listbox-button {
    position: relative;
    width: 100%;
    cursor: default;
    border: none;
    background-color: $light-grey;
    text-align: left;
    padding: 1.5rem 1.5rem;
    padding-right: 2.25rem;
    font-family: $sans-serif-bold;
    font-size: 1.5rem;

    &.is-disabled {
      opacity: 0.4;
    }

    .arrow-icon {
      transform: rotate(90deg);
    }
  }

  .listbox-selected {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    // text-transform: capitalize;
    text-overflow: ellipsis;
  }

  .listbox-chevron {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    &.is-disabled {
      cursor: not-allowed;
    }

    padding-right: 1rem;

    svg {
      height: 1.25rem;
      width: 1.25rem;
    }
  }

  .transition-leave {
    transition-property: color, background-color, border-color,
      text-decoration-color, fill, stroke, opacity, box-shadow, transform,
      filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .transition-leavefrom {
    opacity: 100;
  }

  .transition-leaveto {
    opacity: 0;
  }

  .listbox-options {
    position: absolute;
    z-index: 3;
    background: $white;
    width: 100%;
    max-height: 20rem;
    overflow: auto;
    border-radius: 2px;
    box-sizing: border-box;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
      rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    margin-bottom: 0;
    padding: 1rem;

    @include until-widescreen {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }

  .listbox-option {
    position: relative;
    cursor: pointer;
    user-select: none;
    background-color: $white;

    padding: 0;
    margin-bottom: 0;

    &::before {
      display: none;
    }

    &.is-active {
      background-color: $light-grey;

      * {
        color: $black;
      }
    }

    &.is-selected {
      background-color: $grey;

      * {
        color: $white;
      }
    }

    .listbox-item {
      padding: 0.25rem 0.5rem;
    }
  }

  &.input {
    .label {
      position: absolute;
      top: 18px;
      font-family: $sans-serif-book;
      left: 0.25rem;
      z-index: 1;
      font-size: 0.8rem;
      transition: all 0.3s ease;
      color: $dark-grey;
    }

    &.selected {
      .label {
        top: -20px;
        z-index: unset;
        color: $magenta;
      }
    }

    .listbox-button {
      background-color: $white;
      font-family: $sans-serif-book;
      font-size: 1rem;
      border-bottom: 1px solid $dark-grey;
      padding: 0 0.25rem 0.25rem;

      .arrow-icon {
        margin-right: -0.5rem;
      }
    }

    &.has-error {
      .listbox-button {
        border-bottom-color: $red;
      }
    }

    .listbox-selected {
      color: $dark-grey;
    }

    .listbox-chevron {
      bottom: 10px;
    }
  }
}
