.autor-header {
  @include desktop {
    padding: 1rem;
  }

  .autor-single {
    display: grid;
    grid-template-columns: 1fr;
    @include desktop {
      padding: 1rem;
    }
    place-items: center;

    @include desktop {
      grid-template-columns: 1fr 3fr;
      place-items: start;
    }

    .image-wrapper {
      display: grid;
      width: 100%;
      padding: 0 1rem;
      justify-items: center;
      @include desktop {
        justify-items: start;
        padding: 0;
      }

      .is-164x164 {
        max-width: 240px;
        max-width: 240px;
      }
    }

    // .is-164x164 {
    //   max-width: 240px;
    //   img {
    //     max-width: 240px;
    //   }
    // }

    .content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      max-width: 600px;
      gap: 0.5rem;

      padding: 0 1rem;

      margin-top: 3.5rem;
      overflow: hidden;
      .upper-line {
        display: flex;
        align-items: center;
        .animation-wrapper {
          display: flex;
          align-items: center;
          flex: 1 1 auto;
          overflow: hidden;
          margin: 0 0.5rem;
          span {
            overflow: hidden;
            content: "";
            flex: 1 1 auto;
            border-top: 1px solid $dark-grey;
          }
        }
      }
    }

    .post-count,
    .topic {
      span {
        display: flex;
      }
    }
  }
}
