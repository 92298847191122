.logos-mod {
  &.has-white-bg {
    .with-bg {
      background-color: #c1c1c11a;
    }
  }
  &.has-grey-bg {
    .with-bg {
      background-color: #ffffff8c;
    }
  }

  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .with-bg {
      background-color: #c1c1c10d;
    }
  }

  .media-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    width: 180px;
    height: 80px;

    @include tablet {
      width: 280px;
      height: 180px;
    }
  }

  .grid-multiline {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.875rem;
    width: 100%;

    @include tablet {
      grid-template-columns: repeat(2, 1fr);
    }

    @include desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include widescreen {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .grid-container {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    gap: 0.875rem;
  }

  .grid {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &.with-bg {
      position: relative;
      transition: background-color 0.3s ease-in;
      &:hover {
        background-color: transparent;
      }
    }
  }

  .logos-slider {
    @include reset-ul-li;

    .embla-container {
      display: flex;
      align-items: center;
      margin: 0;
      height: 100%;
      .embla-slide {
        position: relative;
        min-width: 0;
        margin-right: 1rem;
        height: 100%;
        flex: 0 0 33.3333%;
        @include desktop {
          flex: 0 0 20%;
        }
      }
    }
  }

  .logo-link {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}
