.loadmore-pagination {
  .btn-wrapper,
  .pagination-column {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination-wrapper {
    margin-top: 0;
  }
}
