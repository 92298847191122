.animation-tooltip {
  position: fixed;
  pointer-events: none;
  transform: translate(80%, 80%);
  transform-origin: center;
  .tooltip-wrapper {
    width: 200px;
    padding: 1rem;
    background: transparent
      linear-gradient(106deg, #0841db 0%, #ff0b98 52%, #c02733 100%) 0% 0%
      no-repeat padding-box;
    gap: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      width: fit-content;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-width: 15px;
      border-style: solid;
      border-color: #ff0b98 transparent transparent transparent;
      left: 50%;
      transform: translateX(-50%);
      bottom: -29px;
    }
  }
}
