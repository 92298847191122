.cta {
  .container {
    @include mobile_padding;
  }
  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .line-wrapper {
      .line {
        border-bottom: 1px solid $medium-grey;
      }
    }

    .cta-info {
      .cta-person {
        @include desktop {
          border-right: 1px solid $medium-grey;
          padding-right: 2.5rem;
        }
      }
    }
  }

  .overline-cta {
    display: flex;
    align-items: center;
    width: 100%;
    flex: 1 1 auto;
    text-transform: none;
    .overline-cta-text {
      font-size: 0.875rem;
    }
  }

  .line-wrapper {
    overflow: hidden;
    display: flex;
    flex: 1 1 auto;
    margin: 0 0.5rem;
    .line {
      content: "";
      flex: 1 1 auto;
      border-bottom: 1px solid $dark-grey;
    }
  }

  .cta-info {
    margin: 2rem 0;
    .cta-person-quote {
      a {
        color: inherit;
      }
    }
    .cta-person {
      @include desktop {
        border-right: 1px solid $dark-grey;
        padding-right: 2.5rem;
      }
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      @include desktop {
        justify-content: start;
      }

      .cta-person-image {
        max-width: 137px;
      }
    }

    .cta-person-quote {
      text-align: center;

      @include desktop {
        text-align: left;
      }

      p {
        font-size: 1.5rem;
        line-height: 1.875rem;
        font-family: $sans-serif-medium;
      }
      margin-bottom: 1rem;
    }

    .cta-person-name {
      text-align: center;
      display: flex;
      flex-direction: column;
      span {
        line-height: 1.375rem;
      }
      @include desktop {
        text-align: left;
      }
    }

    .cta-action {
      @include desktop {
        padding-left: 2.5rem;
      }
      .cta-action-wrapper {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;

        .cta-headline {
          white-space: break-spaces;
          font-size: 1.5rem;
          line-height: 1.875rem;
          font-family: $sans-serif-medium;
          text-align: center;
          @include desktop {
            text-align: left;
          }
        }

        .cta-content {
          text-align: center;
          @include desktop {
            text-align: left;
          }
          p {
            white-space: break-spaces;
            line-height: 1.375rem;
          }
        }

        .cta-button-wrapper {
          display: flex;
          justify-content: center;
          @include desktop {
            justify-content: start;
          }
        }
      }
    }
  }
}
