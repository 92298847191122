html {
  scroll-behavior: auto;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  // box-sizing: inherit;
  // -webkit-font-smoothing: antialiased;
  box-sizing: inherit;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

.consent-management-container {
  span,
  span b,
  div,
  div p {
    color: initial;
  }
}

body {
  font-family: $sans-serif-book;
  color: $text;
  // background-color: $white;
  overflow: clip;
  font-size: 1rem;
  line-height: 1.5rem;
  scroll-behavior: auto;
  over * {
    outline: none;
  }

  *::-moz-selection {
    color: $white;
    background: $red;
    text-shadow: none;
  }

  *::selection {
    color: $white;
    background: $red;
    text-shadow: none;
  }

  // &::-webkit-scrollbar {
  //   width: 0.5em;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #e4e4e4;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-image: linear-gradient(
  //     180deg,
  //     rgb(192, 39, 51) 0%,
  //     rgb(255, 11, 152) 50%,
  //     rgb(8, 65, 219) 100%
  //   );
  //   // rgb(8, 65, 219) 0%,
  //   // rgb(255, 11, 152) 50%,
  //   // rgb(192, 39, 51) 100%
  //   outline: 1px solid slategrey;
  //   border-radius: 100px;
  // }
}

object {
  pointer-events: none;
}

main {
  margin-top: $navbar-height-touch;
  overflow: clip;
  @include desktop {
    margin-top: $navbar-height-desktop;
  }
  transition: margin 700ms;
  background: transparent;

  &.is-single-page {
    margin-top: $navbar-height-touch;
  }
  // overflow: hidden;

  // height: calc(100vh - $navbar-height-touch);
  // width: 100vw;

  // @include tablet {
  //   margin-top: $navbar-height-tablet;
  //   height: calc(100vh - $navbar-height-tablet);
  // }

  // @include desktop {
  //   margin-top: $navbar-height-desktop;
  //   height: calc(100vh - $navbar-height-desktop);
  // }

  // &::-webkit-scrollbar {
  //   width: 0.5em;
  // }

  // &::-webkit-scrollbar-track {
  //   background-color: #e4e4e4;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-image: linear-gradient(
  //     180deg,
  //     rgb(192, 39, 51) 0%,
  //     rgb(255, 11, 152) 50%,
  //     rgb(8, 65, 219) 100%
  //   );
  //   // rgb(8, 65, 219) 0%,
  //   // rgb(255, 11, 152) 50%,
  //   // rgb(192, 39, 51) 100%
  //   outline: 1px solid slategrey;
  //   border-radius: 100px;
  // }
}

////! undo below code if scroll is disabled in html/body and enabled in main
.hidden-menu + main {
  // margin-top: $navbar-height-touch;

  // @include desktop {
  //   margin-top: $navbar-height-tablet;
  // }

  .wiki-filters {
    .letters-container {
      &.fixed-top {
        transition: all 0.5s;
        top: $navbar-height-touch;

        @include tablet {
          top: $navbar-height-tablet;
        }
      }
    }
  }
}

// HTML Elements
h1,
.h1 {
  font-size: 2rem;
  font-family: $sans-serif-light;
  line-height: 2.5rem;
  margin-bottom: 2rem;

  @include tablet {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: 3rem;
  }

  &.big-size {
    @include tablet {
      font-size: 3.5rem;
      line-height: 3.6rem;
    }
  }

  strong {
    font-family: $sans-serif-bold;
    font-weight: normal;
  }
}

h2,
.h2 {
  font-size: 1.5rem;
  font-family: $sans-serif-light;
  line-height: 2rem;
  margin-bottom: 2rem;

  @include tablet {
    font-size: 2rem;
    line-height: 2.5rem;
    margin-bottom: 2.5rem;
  }

  strong {
    font-family: $sans-serif-bold;
    font-weight: normal;
  }
}

h3,
.h3 {
  font-size: 1.25rem;
  font-family: $sans-serif-medium;
  line-height: 1.75rem;
  margin-bottom: 2rem;

  @include tablet {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  strong {
    font-family: $sans-serif-bold;
    font-weight: normal;
  }
}

h1,
h2,
h3,
.h2,
.h3,
.subline,
.cta-person-quote {
  a,
  .a {
    font-family: inherit;
    font-size: inherit;

    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      content: "";
      border-bottom: 1px solid $dark-grey;
      z-index: 1;
      height: 1px;
      width: 0px;
      // width: 100%;
      -webkit-transition: width 0.5s ease-in-out;
      -moz-transition: width 0.5s ease-in-out;
      -o-transition: width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out;
    }

    &.encyclopedia {
      &::after {
        border-bottom: 1px dotted $dark-grey;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;

      &::after {
        width: 100%;
      }
    }
  }
}

.overline {
  font-family: $sans-serif-book;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
}

.sub {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: $sans-serif-book;
  color: $text;

  strong,
  b {
    font-family: $sans-serif-bold;
    font-weight: normal;
  }
}

span {
  strong {
    font-family: $sans-serif-bold;
    color: inherit;
    font-weight: normal;
  }
}

/* Do not allow italic style */
em {
  font-style: normal;
}

p,
li {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: $sans-serif-book;
  color: $text;

  strong,
  b {
    font-family: $sans-serif-bold;
    font-weight: normal;
  }
}

li {
  padding-bottom: 0.5rem;
}

//adding only padding if theres p tag after it
p + p {
  padding-top: 1rem;
}

a {
  color: $dark-grey;
  position: relative;

  &:hover {
    color: inherit;
  }
}

p,
li:not([role="menuitem"]) {
  a,
  .a {
    font-size: 1rem;
    font-family: $sans-serif-book;
    line-height: 1.5rem;
    color: inherit;
    text-decoration: none;
    position: relative;

    &::after {
      position: absolute;
      bottom: -2px;
      left: 0;
      right: 0;
      content: "";
      border-bottom: 1px dotted $dark-grey;
      z-index: 1;
      height: 1px;
      width: 100%;
      -webkit-transition: width 0.5s ease-in-out;
      -moz-transition: width 0.5s ease-in-out;
      -o-transition: width 0.5s ease-in-out;
      transition: width 0.5s ease-in-out;
    }

    &:hover,
    &:focus {
      text-decoration: none;

      &::after {
        width: 0px;
      }
    }

    &.encyclopedia {
      font-family: inherit;
      font-size: inherit;

      &::after {
        border-bottom: 1px dotted $dark-grey;
      }
    }
  }
}

.anchor {
  font-size: 1rem;
  font-family: $sans-serif-book;
  text-transform: uppercase;
  color: $light-grey;

  &:hover,
  &:focus {
    text-decoration: underline;
    color: $medium-grey;
  }
}

ul {
  margin: $spaceSM 0;
  list-style: none;

  &:not(.consent-management-container, .listbox-options) li {
    font-family: $sans-serif-book;
    color: inherit;
    list-style: none;
    list-style-position: outside;
    padding-left: 1.5rem;

    &:last-of-type {
      margin-bottom: 2rem;
    }

    &::before {
      content: url("../images/aunds-bullet.svg");
      border-radius: 100%;
      display: block;
      font-weight: bold;
      width: 0.75rem;
      height: 0.75rem;
      margin-left: -1.5rem;
      position: relative;
      top: 0.75rem;
    }
  }
}

.two-columns {
  ul {
    margin: 0;

    li {
      page-break-inside: avoid;
      break-inside: avoid-column;
    }
  }
}

ol {
  margin: $spaceSM 0 $spaceSM 1rem;

  li {
    font-family: $sans-serif-book;
    color: $text;
    list-style-position: outside;
    padding-left: 0.5rem;

    &:last-of-type {
      margin-bottom: 2rem;
    }

    &::marker {
      color: inherit;
      margin-left: -1rem;
    }
  }
}

.btn {
  display: block;
  position: relative;
  box-sizing: content-box;
  text-transform: uppercase;
  cursor: pointer;
  font-family: $sans-serif-book;
  font-size: 0.875rem;
  text-transform: uppercase;
  background: transparent;
  width: fit-content;

  &.is-primary {
    // padding: 1.125rem 2.8125rem;  //ask desing about paddings
    padding: 1rem 2rem;
    border: 1px solid $light-grey;
    color: $dark-grey;

    div {
      position: absolute;
      background: $aunds-gradient;
      transition: all 350ms ease-in-out;

      &:first-of-type {
        top: -1px;
        left: -1px;
        transform: rotate(180deg);
        height: 30px;
        width: 1px;
      }

      &:nth-of-type(2) {
        top: -1px;
        left: -1px;
        height: 1px;
        width: 30px;
      }

      &:nth-of-type(3) {
        bottom: -1px;
        right: -1px;
        transform: rotate(180deg);
        height: 1px;
        width: 30px;
      }

      &:last-of-type {
        bottom: -1px;
        right: -1px;
        height: 30px;
        width: 1px;
      }
    }

    &:hover,
    &:focus {
      text-decoration-line: none;
      color: $dark-grey;

      div {
        &:first-of-type,
        &:last-of-type {
          height: 103.5%;
        }

        &:nth-of-type(2),
        &:nth-of-type(3) {
          width: 100.75%;
        }
      }
    }
  }

  &.is-secondary {
    overflow: hidden;
    text-decoration: none;

    span {
      transition: opacity 350ms ease-in-out;
      opacity: 0;
      position: relative;
      left: -100%;
    }

    svg {
      transition: all 350ms ease-in-out;
      width: 1rem;
      position: relative;
      left: calc(-100% + 1rem);
    }

    &:hover,
    &:focus {
      span {
        transition-delay: 250ms;
        position: relative;
        opacity: 1;
        left: 0;
        margin-right: 0.5rem;
      }

      svg {
        left: 0;

        path {
          stroke: $red;
        }
      }
    }
  }
}

.image-wrapper {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.section {
  @include ultrahd {
    padding: $section-padding-ultrahd;
  }

  &.is-margin-right {
    margin-right: $section-margin-right-mobile;
    width: calc(100% - $section-margin-right-mobile);

    @include tablet-only {
      margin-right: $section-margin-right-tablet;
      width: calc(100% - $section-margin-right-tablet);
    }

    @include desktop-only {
      margin-right: $section-margin-right-desktop;
      width: calc(100% - $section-margin-right-desktop);
    }

    @include widescreen-only {
      margin-right: $section-margin-right-wide;
      width: calc(100% - $section-margin-right-wide);
    }

    @include fullhd {
      margin-right: $section-margin-right-fullhd;
      width: calc(100% - $section-margin-right-fullhd);
    }
  }

  @include tablet-only {
    padding: $section-padding-tablet;
  }

  &.is-gapless {
    @include tablet {
      padding: $column-gap;
    }

    &-top-bottom {
      padding: 0;

      @include tablet-only {
        padding: 0 $section-padding-tablet;
      }

      @include desktop {
        padding: 0 $section-padding-desktop;
      }

      @include ultrahd {
        padding: 0 $section-padding-ultrahd;
      }
    }

    &-top {
      padding-top: 0;
    }

    &-bottom {
      padding-bottom: 0;
    }
  }
}

.image-caption {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.is-column-reverse {
  display: flex;
  flex-direction: column-reverse;

  &-mobile {
    @include mobile {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-tablet {
    @include tablet-only {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-desktop {
    @include desktop {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-widescreen {
    @include widescreen {
      display: flex;
      flex-direction: column-reverse;
    }
  }

  &-fullhd {
    @include fullhd {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}

.is-row-reverse {
  display: flex;
  flex-direction: row-reverse;

  &-mobile {
    @include mobile {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &-tablet {
    @include tablet-only {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &-desktop {
    @include desktop {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &-widescreen {
    @include widescreen {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &-fullhd {
    @include fullhd {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.has-overlay {
  position: relative;

  &-mobile {
    @include mobile {
      position: relative;

      .is-overlay-mobile {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-tablet {
    @include tablet-only {
      position: relative;

      .is-overlay-tablet {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-desktop {
    @include desktop {
      position: relative;

      .is-overlay-desktop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-widescreen {
    @include widescreen {
      position: relative;

      .is-overlay-widescreen {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  &-fullhd {
    @include fullhd {
      position: relative;

      .is-overlay-fullhd {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .is-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

*:not(.has-overlay) > .is-overlay {
  position: fixed !important;
  width: 100vw;
  height: 100vh;
}

.is-half-container-right,
.is-half-container-left {
  padding-left: $spaceSM;
  padding-right: $spaceSM;

  @include tablet {
    padding-left: $spaceMD;
    padding-right: $spaceMD;
  }

  @include desktop {
    padding: 0;
    max-width: calc(($desktop / 2) - $gap);
  }

  @include widescreen {
    max-width: calc(($widescreen / 2) - $gap);
  }

  @include fullhd {
    max-width: calc(($fullhd / 2) - $gap);
  }
}

.is-half-container-right {
  margin-left: 0;
  margin-right: auto;

  @include desktop {
    padding: $column-gap;
    $val1: calc((100vw / 12) + $column-gap);
    $val2: calc(($desktop / 12) + $column-gap);
    padding-left: min($val1, $val2);
  }

  @include widescreen {
    $val1: calc((100vw / 12) + $column-gap);
    $val2: calc(($widescreen / 12) + $column-gap);
    padding-left: min($val1, $val2);
  }

  @include fullhd {
    $val1: calc((100vw / 12) + $column-gap);
    $val2: calc(($fullhd / 12) + $column-gap);
    padding-left: min($val1, $val2);
  }
}

.is-half-container-left {
  margin-right: 0;
  margin-left: auto;

  @include desktop {
    padding: $column-gap;
    $val1: calc((100vw / 12) + $column-gap);
    $val2: calc(($desktop / 12) + $column-gap);
    padding-right: min($val1, $val2);
  }

  @include widescreen {
    $val1: calc((100vw / 12) + $column-gap);
    $val2: calc(($widescreen / 12) + $column-gap);
    padding-right: min($val1, $val2);
  }

  @include fullhd {
    $val1: calc((100vw / 12) + $column-gap);
    $val2: calc(($fullhd / 12) + $column-gap);
    padding-right: min($val1, $val2);
  }
}

.container {
  padding: $column-gap;
  &:not(.is-max-desktop):not(.is-max-widescreen) {
    @include ultrahd {
      max-width: 1600px;
    }
  }
}

.has-white-bg {
  background-color: $white;
}

.has-grey-bg {
  background-color: $light-grey;

  .btn {
    &.is-primary {
      border: 1px solid $white;
      color: $dark-grey;

      &:hover {
        color: $dark-grey;
      }
    }
  }
}

.has-darkgrey-bg,
.has-dark-grey-bg {
  background-color: $dark-grey;

  * {
    color: $white;
  }

  svg {
    path {
      stroke: $white;
    }
  }

  *::-moz-selection {
    color: $text;
    background: $white;
    text-shadow: none;
  }

  *::selection {
    color: $text;
    background: $white;
    text-shadow: none;
  }

  p,
  li:not([role="menuitem"]),
  .content {
    a,
    .a {
      &::after {
        border-bottom: 1px dotted $medium-grey;
      }

      &.encyclopedia {
        &::after {
          border-bottom: 1px dotted $medium-grey;
        }
      }
    }
  }

  h1,
  h2,
  h3,
  .h2,
  .h3,
  .subline,
  .cta-person-quote {
    a,
    .a {
      &::after {
        border-bottom: 1px solid $white;
      }

      &.encyclopedia {
        &::after {
          border-bottom: 1px dotted $light-grey;
        }
      }
    }
  }

  .btn {
    &.is-primary {
      border: 1px solid $white;
      color: $white;

      &:hover {
        color: $white;
      }
    }
  }
}

.has-gradient-bg {
  background: $aunds-gradient;

  * {
    color: $white;
  }

  svg {
    path {
      stroke: $white;
    }
  }

  *::-moz-selection {
    color: $text;
    background: $white;
    text-shadow: none;
  }

  *::selection {
    color: $text;
    background: $white;
    text-shadow: none;
  }

  .btn {
    &.is-primary {
      border: 1px solid $white-50;
      color: $white;

      div {
        background: $white;
      }

      &:hover {
        color: $white;
      }
    }
  }

  h1,
  h2,
  h3,
  .h2,
  .h3,
  .subline,
  .cta-person-quote {
    a,
    .a {
      &::after {
        border-bottom: 1px solid $white;
      }

      &.encyclopedia {
        &::after {
          border-bottom: 1px dotted $white;
        }
      }
    }
  }

  p,
  li:not([role="menuitem"]),
  .content {
    a,
    .a {
      &::after {
        border-bottom: 1px dotted $white;
      }

      &.encyclopedia {
        color: inherit;

        &::after {
          border-bottom: 1px dotted $white;
        }
      }
    }
  }
}

// Pagination global styles. TODO: Proof styling, it is copied from old one
.pagination-wrapper {
  display: flex;
  flex-flow: row wrap;
  margin: 2rem auto 0;
  width: fit-content;
  justify-content: space-evenly;
  align-items: center;

  .page-number,
  .next,
  .prev {
    font-family: $sans-serif-medium;

    cursor: pointer;
    color: $grey;
    font-size: 1rem;
    line-height: 1;

    @include desktop {
      font-size: 1.5rem;
      line-height: 1;
    }

    svg {
      stroke-width: 1 !important;
      width: 1.5rem;
      height: 1.5rem;
      position: relative;
      // top: -3px;
    }
  }

  .next,
  .prev {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .page-number-cont {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.4rem;

    width: 35px;
    height: 35px;

    @include desktop {
      width: 50px;
      height: 50px;
    }

    @include tablet {
      margin: 0.5rem;
    }

    &::after {
      content: "";
      position: absolute;
      background: transparent;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: 0;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.3s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    &.active-page {
      &::after {
        opacity: 1;
        background: transparent
          linear-gradient(
            123deg,
            #0841db 0%,
            #5231c7 17%,
            #9b21b3 31%,
            #d11794 46%,
            #f11182 61%,
            #e11868 77%,
            #d21f51 89%,
            #c02733 100%
          )
          0% 0% no-repeat padding-box;
      }

      .page-number {
        color: $white;
      }
    }

    .page-number {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      &.dots {
        content: "";
        height: 1px;
        background: $grey;
        width: 25px;
        @include tablet {
          width: 35px;
        }
        @include desktop {
          width: 50px;
        }
        position: relative;
        top: 19px;
      }
    }
  }

  .prev {
    transform: rotate(90deg);
  }

  .next {
    transform: rotate(-90deg);
  }

  .prev {
    transform: rotate(90deg);
  }

  .next {
    transform: rotate(-90deg);
  }
}

.loadmore-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 3.125rem;

  button {
    background: transparent;
    border: none;
    user-select: none;
    cursor: pointer;
    display: flex;
  }
}

// Breadcrumb. TODO: Proof styling, it is copied from old one
.breadcrumb-aunds {
  padding: $spaceSM;

  a {
    font-size: 0.75rem;
  }

  @include tablet {
    padding-right: clamp(6rem, 8.333333%, 8.333333%);
    padding-left: clamp(6rem, 8.333333%, 8.333333%);
  }

  @include desktop {
    padding-right: clamp(8rem, 8.333333%, 8.333333%);
    padding-left: clamp(8rem, 8.333333%, 8.333333%);
  }

  a {
    color: $medium-grey;

    &.is-active {
      color: $red;
    }
  }
}

.content-blocker__wrapper {
  width: 100% !important;

  &.has-video-thumbnail {
    .content-blocker__bg-image-wrapper img {
      opacity: 0;
    }
  }

  .content-blocker-buttons {
    justify-content: flex-end;
  }

  .content-blocker-external-link {
    a {
      padding-top: 0.25rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
}

.soundcloud-iframe {
  height: auto;
  width: auto;
  border: none;
}

.frame-box-wrapper {
  position: relative;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.1s ease-in-out;

  .arrow {
    transition: transform 0.3s ease-in;
  }

  &::after,
  &::before {
    transition: opacity 0.3s ease-in, width 0.3s ease-in-out,
      height 0.3s ease-in-out;
    position: absolute;
    width: 0;
    height: 0;
    background: $aunds-gradient;
    content: " ";
    opacity: 0;
    z-index: 1;
  }

  &::after {
    top: 0;
    left: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
    transform: rotate(270deg);
  }

  &::before {
    top: 0;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
  }

  .corner-div {
    position: absolute;
    transition: opacity 0.3s ease-in, width 0.3s ease-in-out,
      height 0.3s ease-in-out;
    width: 0;
    height: 0;
    background: $aunds-gradient;

    &:nth-of-type(2) {
      bottom: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      transform: rotate(180deg);
    }

    &:nth-of-type(3) {
      bottom: 0;
      right: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      transform: rotate(90deg);
    }
  }

  &:hover {
    background-color: $white;

    &::after,
    &::before {
      opacity: 1;
      width: 80px;
      height: 80px;

      @include desktop {
        width: 60px;
        height: 60px;
      }
    }

    .corner-div {
      opacity: 1;
      width: 80px;
      height: 80px;

      @include desktop {
        width: 60px;
        height: 60px;
      }
    }

    .arrow {
      transform: translate(20px, 0);
    }
  }
}

.hide-overflow {
  overflow: hidden;
}

.my-1_8 {
  margin-top: 1.8rem;
  margin-bottom: 1.8rem;
}

picture {
  display: inherit;
}

// style for input
.input-group {
  position: relative;
  width: 100%;

  // input[type="text"],
  // input[type="number"],
  // input[type="textarea"]
  input {
    background: none;
    color: $dark-grey;
    font-size: 18px;
    padding: 10px 10px 3px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $dark-grey;
    font-family: $sans-serif-book;

    &:focus {
      outline: none;
    }

    &:not(:placeholder-shown) ~ label,
    &:focus ~ label {
      top: -14px;
      font-size: 12px;
      color: $magenta;
    }

    &:focus ~ .bar:before {
      width: 100%;
    }
  }

  label {
    color: $grey;
    font-size: 0.8rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: all 0.3s ease;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;

    &:before {
      content: "";
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $magenta;
      transition: width 0.5s ease-in-out;
      left: 0%;
    }
  }
}

input {
  &.has-error {
    border-color: $red;
  }
}

.file {
  input[type="file"] {
    cursor: pointer;
  }

  label,
  .download-icon {
    padding-bottom: 0.1rem;
  }
}

.error-message {
  color: $red;
  padding-bottom: 0;
  font-size: 0.75rem;
  padding-left: 0.25rem;
}

.input-checkbox {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 1em;
  font-size: 0.875rem;
  line-height: 1.1;

  // align-items: center;
  a {
    font-size: 0.875rem;
    @include anchor__line_animation;
  }

  p {
    padding-bottom: 0;
  }

  input {
    height: 20px;
    width: 20px;
    outline: 1px solid $red;
    outline-offset: -1px;
    accent-color: rgb(255, 11, 152);
    cursor: pointer;
  }

  label {
    cursor: pointer;
  }
}

// Datepicker
.date-input {
  column-gap: 0.25rem;
  position: relative;

  .label {
    position: absolute;
    top: -21px;
    font-family: $sans-serif-book;
    color: $magenta;
    font-size: 0.8rem;
    left: 0.25rem;
  }

  .react-datepicker-wrapper {
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid $dark-grey;
      font-family: $sans-serif-book;
      font-size: 0.875rem;
      padding-bottom: 0.25rem;
      padding-left: 0.25rem;

      &::placeholder {
        color: $dark-grey;
        font-size: 0.8rem;
      }
    }

    .react-datepicker__input-container {
      &::after {
        content: url("../images/icon-calendar.svg");
        width: 2rem;
        height: 2rem;
        position: absolute;
        right: 0;
        bottom: 0;
        padding-left: 2px;
        border-bottom: 1px solid $white;
        cursor: pointer;
      }
    }
  }

  .react-datepicker-popper {
    z-index: 2;

    .react-datepicker__day {
      font-family: $sans-serif-book;
    }

    .react-datepicker__triangle {
      &::after,
      &::before {
        border: none !important;
      }
    }
  }

  &.error {
    .react-datepicker-wrapper {
      input {
        border-bottom-color: $red;
      }
    }
  }
}

.textarea {
  padding: 1rem;

  textarea {
    background: $light-grey;
    border-radius: 0px;
    box-shadow: none;
    resize: none;
    border: none;
    border-bottom: 1px solid $dark-grey;
    padding: 1rem;
    vertical-align: top;
    font-family: $sans-serif-book;
    color: $dark-grey;
    font-size: 18px;
    width: 100%;

    &:focus ~ .bar:before {
      width: 100%;
    }
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;

    &:before {
      content: "";
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: $magenta;
      transition: width 0.5s ease-in-out;
      left: 0%;
    }
  }
}

.hide-all-bg {
  transition: all 0.3s ease-in;

  section {
    &.has-white-bg,
    &.has-grey-bg,
    &.has-gradient-bg,
    &.has-darkgrey-bg,
    &.has-dark-grey-bg {
      background: transparent;
    }
  }
}

.section-divider-bg {
  position: fixed;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s ease-in;
}

.w-full {
  width: 100%;
}
.hw-full {
  width: 100%;
  height: 100%;
}

.h-100 {
  height: 100%;
}

.consent-management-container {
  ul {
    margin: 0;

    .coniZT {
      color: $purple;

      &:hover {
        color: $white;
      }
    }
    li {
      color: $white;
      &:last-of-type {
        margin-bottom: 0;
      }

      &::before {
        display: none;
      }
    }

    &.textlinks {
      @include touch {
        margin-bottom: 0.5rem !important;
      }

      margin-top: 0.5rem;

      li {
        padding-left: 0;

        a {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}

@mixin height__without_navbar {
  height: calc(100dvh - $navbar-height-touch);

  @include tablet {
    height: calc(100dvh - $navbar-height-tablet);
  }
}

@mixin mobile_padding {
  @include mobile {
    padding-left: 30px;
    padding-right: 30px;
  }
}
.is-reverse {
  @include touch {
    display: flex;
    flex-direction: column-reverse;
  }
}

.w-100 {
  width: 100%;
}

.is-absolute {
  position: absolute;
}

details {
  summary {
    list-style-type: none;
  }
}

.loader-sticky-wrapper {
  position: sticky;
  position: -webkit-sticky;
  top: $navbar-height-touch; /* required */
  max-height: calc(100vh - $navbar-height-touch);
  height: 100%;
  width: 100%;
  display: flex;

  .loading-screen {
    background: transparent;
  }
}

section:not(.text) > .container:first-child {
  @include mobile_padding;
}

.page-scroll-down {
  @media (min-width: 824px) and (max-height: 780px) {
    display: none;
  }
}
.loader {
  width: 30px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side, #000 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  // stylelint-disable-next-line declaration-no-important
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}

button {
  &:disabled {
    opacity: 0.5;
  }
}
