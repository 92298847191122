.portfolio-grid {
  .container {
    @include mobile_padding;
  }
  &.has-gradient-bg,
  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .category-item {
      .category-item {
        span {
          &::after {
            background: $white;
          }
        }
      }
    }
  }

  .category-grid {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
    @include mobile {
      margin: 1.5rem 0;
      flex-wrap: nowrap;
      justify-content: unset;
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        width: 0 !important;
      }
    }

    .category-item {
      text-decoration: none;
      color: inherit;
      padding: 2px 1.5rem;
      display: flex;
      justify-content: center;
      //   border-right: 1px solid $dark-grey;
      white-space: nowrap;
      line-height: 1;
      &:last-of-type {
        border: none;
      }

      &.active-cat {
        color: $red;
        span {
          position: relative;
          &::after {
            position: absolute;
            bottom: -2px;
            left: 0;
            right: 0;
            content: "";
            background: $red;
            z-index: 2;
            height: 1px;
            width: 100%;
            -webkit-transition: width 0.5s ease-in-out;
            -moz-transition: width 0.5s ease-in-out;
            -o-transition: width 0.5s ease-in-out;
            transition: width 0.5s ease-in-out;
            border-radius: 1px;
          }
        }
      }

      @include line__animtion;

      span {
        &::after {
          background: $dark-grey;
        }
      }
    }
  }

  .portfolio-element {
    padding: 1.5rem;
    // min-height: 184px;
    // min-width: 337px;
    height: 100%;
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    aspect-ratio: 3 / 2;

    .categories {
      // display: flex;
      column-gap: 1rem;
      // flex-wrap: wrap;

      .category {
        @include line__animtion;
        color: $white;
        text-decoration: none;
        border-left: 1px solid $white;
        padding: 0 5px;
        &:first-child {
          border: none;
        }
      }
      span {
        font-family: $sans-serif-bold;
        font-size: 0.75rem;
        line-height: 1.875rem;
        text-transform: uppercase;
      }
    }

    .portfolio-title {
      color: $white;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .portfolio-link {
      color: $white;
      text-decoration: none;
    }
  }

  .portfolio-slide-wrapper {
    display: flex;
    flex-wrap: nowrap;

    .portfolio-slide {
      display: flex;
      justify-content: space-between;
      flex-wrap: nowrap;
    }
  }

  .two-column-portfolio-grid {
    &.padding-top {
      @include desktop {
        padding-top: 3.75rem;
      }
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 1.25rem;
      cursor: pointer;

      @include desktop {
        justify-content: center;
      }

      @include touch {
        padding-top: 3.75rem;
      }
    }

    &:nth-child(1) {
      .column:nth-child(1) {
        @include touch {
          padding-top: 1rem;
        }
      }

      .column:nth-child(2) {
        @include tablet-only {
          padding-top: 1rem;
        }
      }
    }

    .img-big-wrapper,
    .img-small-wrapper {
      position: relative;
      aspect-ratio: 16 / 9;
      overflow: hidden;

      img {
        transition: transform 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }

      video {
        position: absolute;
        object-fit: cover;
        aspect-ratio: 16 / 9;
        top: 0;
        left: 0;
      }
    }

    .information {
      display: flex;
      flex-direction: column;
      .title {
        font-family: $sans-serif-bold;
        font-size: 26px;
        line-height: 29px;
      }

      .overline {
        padding: 0 2.5px;
        font-size: 13px;
        text-transform: none;
      }
    }
    .categories {
      display: flex;
      flex-wrap: wrap;

      .category {
        padding: 0 2.5px;
        font-size: 13px;
        &:not(:last-child)::after {
          position: relative;
          content: "|";
          left: 2.5px;
        }
      }
    }
  }
}
