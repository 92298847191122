.scroll-down {
  position: absolute;
  bottom: 0.85rem;

  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;

  width: 30px;
  height: 30px;

  @include ultrahd {
    width: 50px;
    height: 50px;
  }
}
