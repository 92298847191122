.video-slider-mod {
  .embla-slider {
    @include reset-ul-li;
    overflow: hidden;
    .embla-container {
      display: flex;
      height: 100%;

      .embla-slide {
        margin-right: 20px;
        @include widescreen {
          margin-right: 75px;
        }
        min-width: 0;
        height: 100%;
        flex: 0 0 40%;
      }
    }
  }

  .video-slider-navigations {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.5rem;

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 1rem;
    }

    .next,
    .prev {
      display: grid;
      place-items: center;
    }
  }
}
