.banner-job {
  .container {
    padding: 2rem;
    @include desktop {
      padding: 3rem;
    }
  }

  .banner-job-content {
    *,
    p {
      color: $white;
    }
  }

  .job-banner-columns {
    position: relative;
    padding: 0.5rem;

    @include desktop {
      &::after {
        content: "";
        position: absolute;
        height: 80%;
        width: 1px;
        background: $white;
        left: 50%;
        right: 50%;
        top: 10%;
      }
    }

    .job-banner-column-left,
    .job-banner-column-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      @include desktop {
        padding: 3.125rem;
        align-items: baseline;
      }
    }

    align-items: center;

    .job-banner-column-left {
      border-bottom: 1px solid $white;
      @include desktop {
        border-bottom: none;
      }
    }
  }
}
