.carousel {
  &.is-paused {
    .carousel-slide-track {
      animation-play-state: paused;
    }
  }
  // Animation
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(
        calc(var(--slide-width-neg) * var(--size-infinite))
      );
    }
  }

  @mixin white-gradient {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  @mixin gray-gradient {
    background: linear-gradient(
      to right,
      rgba(245, 245, 245, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  // Styling
  .carousel-slider {
    background: transparent;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100vw;

    &::after {
      right: 0;
      top: 0;
      transform: rotateZ(180deg);
      transition: transform 0.3s;
    }

    &::before {
      left: 0;
      top: 0;
    }

    .carousel-slide-track {
      animation: scroll var(--animtion-speed) linear infinite;
      display: flex;
      width: calc(var(--slide-width) * var(--size-infinite) * 2);
      // width: 43000px;
      &:hover {
        animation-play-state: paused;
      }
    }

    .carousel-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      width: 250px;
    }
  }
}
