.section.text {
  .max-width {
    max-width: 55rem;
  }
  .container {
    @include mobile_padding;
  }
}

.text-content-wrapper {
  h2,
  h3 {
    margin: 0;
  }

  .subline {
    display: inline-block;
    color: inherit;
    font-size: 1.25rem;
    line-height: 1.75rem;
    * {
      font-size: 1.25rem;
      line-height: 1.75rem;
    }
  }

  .two-columns {
    @include desktop {
      columns: 2;
      column-gap: 1.5rem;
    }
  }

  .has-text-centered {
    .text-link {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .link-wrapper {
    display: flex;
    justify-content: center;
  }

  ul {
    margin-left: 2rem;
  }
}
