.joinus-header {
  .title {
    margin-bottom: 0rem;
    overflow: hidden;
  }
  .underline {
    display: inline-block;
    font-size: 1.5rem;
    line-height: 1.625;
    font-family: $sans-serif-bold;
    margin-bottom: 2.5rem;
  }

  .overflow-hidden {
    overflow: hidden;
  }

  .media-wrapper {
    display: flex;
    justify-content: center;
  }
}
