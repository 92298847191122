// A-Z Filter Styling
.wiki-filters {
  padding-top: 3rem;
  padding-bottom: 3rem;

  .container {
    width: 100%;
  }

  .ref-container {
    width: 100%;
    height: 100%;
    min-height: 56px;
  }

  .letters-container {
    display: flex;
    gap: 0.5rem 1.4rem;
    padding: 1rem;
    justify-content: center;
    width: 100%;
    flex-flow: nowrap;
    left: 0;
    z-index: 3;

    &.fixed-top {
      background-color: $white;
      position: fixed !important;
      top: $navbar-height-touch;
      transition: all 0.5s;
      @include desktop {
        top: calc(($navbar-height-tablet * 2) - 1rem);
      }
      padding: 1rem;
      z-index: 3;
    }

    .letter {
      cursor: pointer;
      font-size: 1.25rem;

      &:hover {
        color: $magenta;
      }

      &.active {
        font-family: $sans-serif-bold;
      }
    }
  }

  .filter-slider {
    display: flex;
    width: 100%;

    .embla-slider {
      @include reset-ul-li;
      overflow: hidden;
      .embla-container {
        display: flex;
        height: 100%;

        .embla-slide {
          min-width: 0;
          height: auto;
          flex: 0 0 auto;
        }
      }
    }

    .letter {
      cursor: pointer;
      font-size: 1.25rem;
      padding: 0;
      margin: 1.5rem;
      display: inline-block;

      &:hover {
        color: $magenta;
      }

      &.active {
        font-family: $sans-serif-bold;
      }
    }

    .prev,
    .next {
      background: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      color: inherit;
      padding: 0;
      margin: 0.5rem;
    }
  }
}

.wiki {
  .container {
    width: 100%;
    @include mobile_padding;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  &.more-posts,
  &.search {
    position: relative;
    z-index: 2;
  }
}

// Wiki Box Global
.wiki-box {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  width: 100%;

  * {
    width: 100%;
  }

  &:hover {
    .border-corner {
      opacity: 1;
    }
  }

  .wiki-info {
    position: relative;
    z-index: 1;
    padding: 1.5rem 1.5rem 3.5rem;
    border: 1px solid $white;
    display: flex;
    flex-flow: row wrap;
    height: 100%;
    background-color: $light-grey;

    &:hover {
      background-color: $white;
    }

    .box-title {
      padding-bottom: 1rem;

      .h3 {
        font-family: $sans-serif-bold;
      }
    }

    .description {
      margin-top: auto;
    }
  }

  .border-corner {
    opacity: 0;

    position: absolute;
    background: $aunds-gradient;
    transition: all 350ms ease-in-out;

    height: 40px;
    width: 40px;

    &.upper {
      &.right {
        top: -2px;
        right: -2px;
      }

      &.left {
        top: -2px;
        left: -2px;
        transform: rotate(90deg);
      }
    }

    &.lower {
      &.right {
        bottom: -2px;
        right: -2px;
        transform: rotate(90deg);
      }

      &.left {
        bottom: -2px;
        left: -2px;
        transform: rotate(180deg);
      }
    }
  }
}
