.hero {
  padding: 0.5rem;

  @include tablet {
    padding: 2.25rem;
  }

  height: 100%;

  &.wiki {
    .headline-wrapper {
      @include desktop {
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
    }
  }

  .frame {
    position: relative;
    height: 100%;

    .border {
      height: 100%;

      // padding: 1rem;
      .columns {
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;

        @include desktop {
          flex-direction: row;
        }

        .image-wrapper {
          height: auto;
        }
      }

      .background-full-media {
        position: relative;
        height: 100%;
        display: flex;
        // justify-content: center;
        align-items: center;

        .background-media {
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .background-full-media-content {
          position: relative;
          height: auto;
          z-index: 1;
          display: flex;
          align-items: center;
          margin: 0 3rem;
        }
      }
    }

    &.frame-on {
      .border {
        border: 2px solid $white-50;
      }

      &::after,
      &::before {
        position: absolute;
        background: $white;
        content: " ";
        width: 80px;
        height: 80px;
      }

      &::after {
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
        transform: rotate(270deg);
      }

      &::before {
        top: 0;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      }

      .frame-edge {
        position: absolute;
        background: $white;
        width: 80px;
        height: 80px;

        &:nth-of-type(2) {
          bottom: 0;
          left: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(180deg);
        }

        &:nth-of-type(3) {
          bottom: 0;
          right: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(90deg);
        }
      }
    }
  }

  .content {
    p:first-child {
      padding-bottom: 0;
    }
  }

  picture {
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.page-header {
  &.has-white-bg {
    .frame-on {
      .border {
        border: 2px solid $light-grey;
      }

      &::after,
      &::before {
        background: $medium-grey;
      }

      .frame-edge {
        background: $medium-grey;
      }
    }
  }

  &.fullscreen {
    // height: 100%;

    &.portraitslider {
      height: auto;
    }

    min-height: calc(100vh - $navbar-height-touch);
    // height: calc(100vh - $navbar-height-touch);

    @include tablet {
      min-height: calc(100vh - $navbar-height-tablet);
      // height: calc(100vh - $navbar-height-tablet);
    }

    @include desktop {
      $desktopheight: calc(100vh - $navbar-height-desktop);
      min-height: $desktopheight;
      // height: calc(100vh - $navbar-height-desktop);
    }

    // .animation-player {
    //   width: 100%;
    //   height: 100%;
    // }
  }

  &.halfscreen {
    min-height: calc(50vh - $navbar-height-touch);

    @include tablet {
      min-height: calc(50vh - $navbar-height-tablet);
    }

    @include desktop {
      min-height: calc((50vh - $navbar-height-desktop));
    }
  }

  .headline-wrapper {
    .logos {
      .logo {
        margin-right: 1.5rem;

        img {
          width: 100%;
          max-height: 2.5rem;
        }
      }
    }
  }

  .padding-6 {
    @include tablet {
      padding: 3rem 3rem 0 3rem;
    }
  }

  .text-media {
    .media-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;

      .animation-player {
        height: 280px;
        width: auto;
      }
    }
  }

  .content {
    &.two-columns {
      @include desktop {
        columns: 2;
        column-gap: 2.5rem;
      }
    }
  }
}

.content {
  &.two-columns {
    @include desktop {
      columns: 2;
      column-gap: 2.5rem;
    }
  }
}

.padding-6 {
  @include tablet {
    padding: 3rem 3rem 0 3rem;
  }
}

.padding-top-0 {
  @include tablet {
    padding-top: 0;
  }
}

@import "./headers/textHeader";
@import "./headers/ctaBoxHeader";
@import "./headers/textMediaHeader";
@import "./headers/heroHeader";
@import "./headers/wikiPageHeader";
@import "./headers/animationSlider";

.media-frame-box-wrapper {
  position: relative;
  text-decoration: none;
  color: inherit;
  transition: background-color 0.1s ease-in-out;
  width: 100%;
  // padding: 0.5rem 0.1rem 0.2rem 0.3rem;

  padding: 1rem;

  &:hover {
    .rotate {
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }

  .rotate {
    transition: transform 0.6s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    will-change: transform;
    transform: rotate3d(0, 0, 1, -2deg);
    position: relative;
    z-index: 2;
    box-shadow: 0px 0px 40px #00000057;
    background-color: transparent;
    transform-origin: 1% 50%;
    overflow: hidden;
  }

  &.disable-rotate {
    .rotate {
      transform: rotate3d(0, 0, 1, 0deg);
    }
  }

  video {
    will-change: transform;
    image-rendering: crisp-edges;
  }

  &::after,
  &::before {
    position: absolute;
    background: $aunds-gradient;
    content: " ";
    z-index: 1;
    opacity: 1;
    width: 60px;
    height: 60px;

    @include desktop {
      width: 40px;
      height: 40px;
    }
  }

  &::after {
    top: 0;
    left: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
    transform: rotate(270deg);
  }

  &::before {
    top: 0;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
  }

  .corner-div {
    position: absolute;
    background: $aunds-gradient;
    opacity: 1;
    width: 60px;
    height: 60px;

    @include desktop {
      width: 40px;
      height: 40px;
    }

    &:nth-of-type(2) {
      bottom: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      transform: rotate(180deg);
    }

    &:nth-of-type(3) {
      bottom: 0;
      right: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      transform: rotate(90deg);
    }
  }
}
