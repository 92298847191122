.scroll-animation {
  transition: background-color 1s cubic-bezier(0.12, 0, 0.39, 0) 0.5s;

  * {
    color: $white;
  }

  .animation-wrapper {
    position: sticky;
    top: $navbar-height-touch;
    height: calc(100vh - $navbar-height-touch);
    display: flex;
    //? commented below beacuse it make the lottie smaller (undo if needed)
    // align-items: center;
    justify-content: center;

    .animation {
      padding: 4rem 1.5rem;
      transition: background-color 1s cubic-bezier(0.12, 0, 0.39, 0) 0.5s;
    }
  }

  .element-wrapper {
    display: flex;
    align-items: center;
    @include touch {
      justify-content: center;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    @include desktop {
      height: calc(100vh - $navbar-height-touch);
    }

    .element {
      @include touch {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .overline {
        font-family: $sans-serif-book;
        font-size: 1.125rem;
        line-height: 1.25rem;
        display: block;
        text-transform: none;
        @include touch {
          text-align: center;
          color: $white;
        }
      }

      .title {
        font-family: $sans-serif-bold;

        font-size: 1.5rem;
        line-height: 2.25rem;

        @include tablet {
          font-size: 2rem;
        }

        @include desktop {
          font-size: 2.5rem;
          line-height: 3.125rem;
        }

        display: block;
        text-transform: uppercase;
        word-wrap: break-word;
        overflow-wrap: break-word;

        -webkit-hyphens: auto;
        -moz-hyphens: auto;
        hyphens: auto;
        color: $white;
        @include touch {
          text-align: center;
        }
      }

      .element-button {
        color: $white;

        margin-top: 1.5rem;

        @include desktop {
          margin-top: 2.5rem;
        }
      }
    }
  }

  .track-wrapper-touch {
    .animation-wrapper-touch {
      position: sticky;
      top: $navbar-height-touch;
      height: calc(100dvh - $navbar-height-touch);
      padding: 2rem 1rem;
      display: grid;
      grid-template-rows: 60dvh 1fr;

      @media screen and (max-width: 400px) {
        padding: 0;
      }

      // .animation {
      //   position: relative;
      //   padding: 2rem 0;
      //   // display: grid;
      //   // grid-template-rows: 1fr 1fr;
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-evenly;
      //   gap: 1rem;
      //   height: 100%;
      //   transition: background-color 1s cubic-bezier(0.12, 0, 0.39, 0) 0.5s;

      //   .animation-player {
      //     height: 50vh;
      //   }
      // }
    }
  }
}
