.text-header {
  &.fullscreen {
    min-height: calc(100vh - $navbar-height-touch);
    @include desktop {
      min-height: calc(100vh - $navbar-height-desktop);
    }
  }

  // &.halfscreen {
  //   height: 50vh;
  // }

  &.is-single-page {
    &.fullscreen {
      min-height: calc(100vh - $navbar-height-touch);
    }
  }
  .max-width {
    max-width: 55rem;
  }

  .content {
    .logos {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1rem;
      gap: 0.75rem;

      img {
        width: auto;
        height: 2rem;
        display: block;
      }
    }
  }
}
