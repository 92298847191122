.hero-page {
  &.border-on {
    padding: 0.5rem;

    @include tablet {
      padding: 2.25rem;
    }

    &.fullscreen {
      height: calc(100vh - $navbar-height-touch);
      @include desktop {
        height: calc(100vh - $navbar-height-desktop);
      }

      .main-columns {
        min-height: calc(100vh - $navbar-height-touch - 1rem);
        @include tablet {
          min-height: calc(100vh - $navbar-height-touch - 4.5rem);
        }
        @include desktop {
          min-height: calc(100vh - $navbar-height-desktop - 4.5rem);
        }
      }

      .hero-media-wrapper {
        max-height: calc(100vh - $navbar-height-touch - 1rem);
        @include tablet {
          max-height: calc(100vh - $navbar-height-touch - 4.5rem);
        }
        @include desktop {
          max-height: calc(100vh - $navbar-height-desktop - 4.5rem);
        }
      }

      &.is-animation {
        @include mobile {
          height: 100%;
          .main-columns {
            min-height: 100%;
          }
        }

        @include tablet-only {
          height: 100%;
          .main-columns {
            min-height: 100%;
          }
        }
      }
    }

    &.halfscreen {
      min-height: 50vh;

      .background-full-media {
        min-height: calc(50vh - 1rem);
        @include tablet {
          min-height: calc(50vh - 4.5rem);
        }
      }

      .main-columns {
        min-height: calc(50vh - 1rem);
        @include tablet {
          min-height: calc(50vh - 4.5rem);
        }
      }

      .svg-image {
        max-height: calc(50vh - 1rem);
        @include tablet {
          max-height: calc(50vh - 4.5rem);
        }
      }

      // .hero-media-wrapper {
      //   // min-height: calc(50vh - 1rem);
      //   // @include tablet {
      //   //   min-height: calc(50vh - 4.5rem);
      //   // }
      // }
    }

    &.is-single-page {
      &.fullscreen {
        min-height: calc(100vh - $navbar-height-touch);

        .main-columns {
          min-height: calc(100vh - $navbar-height-touch - 1rem);
          @include tablet {
            min-height: calc(100vh - $navbar-height-touch - 4.5rem);
          }
          @include desktop {
            min-height: calc(100vh - $navbar-height-desktop - 4.5rem);
          }
        }
      }
    }
  }

  &.border-off {
    &.fullscreen {
      height: calc(100vh - $navbar-height-touch);
      @include desktop {
        height: calc(100vh - $navbar-height-desktop);
      }

      .main-columns {
        min-height: calc(100vh - $navbar-height-touch);
        @include tablet {
          min-height: calc(100vh - $navbar-height-touch);
        }
        @include desktop {
          min-height: calc(100vh - $navbar-height-desktop);
        }
      }

      .hero-media-wrapper {
        max-height: calc(100vh - $navbar-height-touch);
        @include tablet {
          max-height: calc(100vh - $navbar-height-touch);
        }
        @include desktop {
          max-height: calc(100vh - $navbar-height-desktop);
        }
      }

      &.is-animation {
        @include mobile {
          height: 100%;
          .main-columns {
            min-height: 100%;
          }

          padding-bottom: 1.5rem;
        }

        @include tablet-only {
          height: 100%;
          .main-columns {
            min-height: 100%;
          }

          padding-bottom: 1.5rem;
        }
      }
    }

    &.halfscreen {
      min-height: 50vh;

      .background-full-media {
        min-height: calc(50vh);
        @include tablet {
          min-height: calc(50vh);
        }
      }

      .main-columns {
        min-height: calc(50vh);
        @include tablet {
          min-height: calc(50vh);
        }
      }

      .svg-image {
        max-height: calc(50vh);
        @include tablet {
          max-height: calc(50vh);
        }
      }
    }

    &.is-single-page {
      &.fullscreen {
        min-height: calc(100vh - $navbar-height-touch);

        .main-columns {
          min-height: calc(100vh - $navbar-height-touch);
          @include tablet {
            min-height: calc(100vh - $navbar-height-touch);
          }
          @include desktop {
            min-height: calc(100vh - $navbar-height-desktop);
          }
        }
      }
    }
  }

  height: 100%;

  .frame {
    position: relative;
    // height: 100%;

    .border {
      height: 100%;
      overflow: hidden;

      .columns {
        height: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;

        @include desktop {
          flex-direction: row;
        }

        .image-wrapper {
          height: auto;
          vertical-align: middle;
        }
      }

      .background-full-media {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;

        .background-media {
          position: absolute;
          z-index: 0;
          width: 100%;
          height: 100%;

          video {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        iframe {
          width: 100vw;
          height: 56.25vw;
          /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
          min-height: 100vh;
          min-width: 177.77vh;
          /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          object-fit: cover;
        }

        .background-full-media-content {
          position: relative;
          height: auto;
          z-index: 1;
          display: flex;
          // align-items: center;

          margin: 0 3rem;

          justify-content: center;

          @include desktop {
            align-items: center;
            justify-content: flex-start;
          }
        }
      }

      .hero-media-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mobile {
          width: 100%;
        }

        @include tablet-only {
          width: 100%;
        }

        // padding: 0;
        &.is-animation {
          padding: 0;
        }

        .inline-video {
          height: 100%;
        }

        .iframe-box {
          aspect-ratio: 16 / 9;
        }
      }
    }

    &.frame-on {
      .border {
        border: 2px solid $white-50;
      }

      &::after,
      &::before {
        position: absolute;
        background: $white;
        content: " ";
        width: 80px;
        height: 80px;
      }

      &::after {
        top: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
        transform: rotate(270deg);
      }

      &::before {
        top: 0;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      }

      .frame-edge {
        position: absolute;
        background: $white;
        width: 80px;
        height: 80px;

        &:nth-of-type(2) {
          bottom: 0;
          left: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(180deg);
        }

        &:nth-of-type(3) {
          bottom: 0;
          right: 0;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
          transform: rotate(90deg);
        }
      }
    }
  }

  .content {
    p:first-child {
      padding-bottom: 0;
    }
  }
}
