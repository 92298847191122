// Font Families
$tablet: 824px;
// $gap: 32px;
$column-gap: 16px;
// $weight-bold: 400;

$navbar-height-touch: 70px;
$navbar-height-tablet: 70px;
$navbar-height-desktop: 126px;

$spaceXS: 16px;
$spaceSM: 32px;
$spaceMD: 64px;
$spaceLG: 80px;
$spaceXL: 96px;

$section-padding: $spaceSM 0;
$section-padding-tablet: $spaceMD;
$section-padding-desktop: $spaceLG;
$section-padding-ultrahd: $spaceXL;

$section-margin-right-mobile: 32px;
$section-margin-right-tablet: 50px;
$section-margin-right-desktop: 67px;
$section-margin-right-wide: 100px;
$section-margin-right-fullhd: 112px;

@font-face {
  font-family: "Gotham Medium";
  src: local("Gotham Medium"),
    url("../fonts/gothmmed-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Gotham Light";
  src: local("Gotham Light"),
    url("../fonts/gothmlig-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Gotham Bold";
  src: local("Gotham Bold"),
    url("../fonts/gothmbol-webfont.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: "Gotham Book";
  src: local("Gotham Book"),
    url("../fonts/gothmbok-webfont.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Gotham Black";
  src: local("Gotham Black"), url("../fonts/gotham-black.woff") format("woff");
  font-display: swap;
}

$sans-serif-medium: "Gotham Medium", Verdana, Tahoma, Arial, Helvetica,
  sans-serif;
$sans-serif-light: "Gotham Light", Verdana, Tahoma, Arial, Helvetica, sans-serif;
$sans-serif-bold: "Gotham Bold", Verdana, Tahoma, Arial, Helvetica, sans-serif;
$sans-serif-book: "Gotham Book", Verdana, Tahoma, Arial, Helvetica, sans-serif;
$sans-serif-black: "Gotham Black", Verdana, Tahoma, Arial, Helvetica, sans-serif;

// Text Color

// Links

// Colors
$white: #ffffff;
$white-50: #ffffff80;
$dark-grey: #333333;
$medium-grey: #c1c1c1;
$light-grey: #f3f3f2;
$grey: #8f8e8e;
$blue: #0841db;
$magenta: #ff0b98;
$red: #c02733;
$purple: #6d67f5;

$text: $dark-grey;
$link: $red;
$aunds-gradient: linear-gradient(
  90deg,
  rgba(8, 65, 219, 1) 0%,
  rgba(82, 49, 199, 1) 15%,
  rgba(155, 33, 179, 1) 30%,
  rgba(209, 23, 148, 1) 45%,
  rgba(241, 17, 130, 1) 60%,
  rgba(225, 24, 104, 1) 75%,
  rgba(210, 31, 81, 1) 90%,
  rgba(192, 39, 51, 1) 100%
);
$navbar: $light-grey;
// $primary: $dark-green;

// Mixins
@mixin ultrahd {
  @media screen and (min-width: 1920px) {
    @content;
  }
}

$modal-background-background-color: rgb(255 255 255 / 86%);
