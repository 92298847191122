.jobs {
  a {
    text-decoration: none;
    color: inherit;
  }

  &.has-white-bg {
    .frame-box-wrapper {
      border: 2px solid $light-grey;
    }
  }
  &.has-grey-bg,
  &.has-dark-grey-bg,
  &.has-darkgrey-bg {
    .job-card {
      border: 2px solid $white;
    }
  }

  .job-card {
    padding: 1.5rem;

    .job-card-body {
      div {
        display: flex;
      }

      .job-media {
        align-items: center;
        justify-content: flex-start;
        @include desktop {
          justify-content: center;
        }
      }

      .job-content {
        justify-content: center;
        flex-direction: column;
        gap: 0.5rem;

        .upperline {
          font-size: 1.25rem;
          line-height: 1.75rem;
        }

        .title {
          font-size: 2rem;
          line-height: 2.75rem;
          font-family: $sans-serif-bold;
        }
      }

      .job-action {
        align-items: flex-end;
        justify-content: flex-start;
        @include desktop {
          justify-content: center;
        }
      }
    }
  }

  .frame-box-wrapper {
    &:hover {
      &::after,
      &::before {
        width: 50px;
        height: 50px;

        @include desktop {
          width: 40px;
          height: 40px;
        }
      }

      .corner-div {
        width: 50px;
        height: 50px;

        @include desktop {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
