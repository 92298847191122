.header-autor-insights {
  a {
    color: inherit;
    text-decoration: none;
  }

  .insight-wrapper {
    padding: 2.5rem;
    border: 2px solid $light-grey;
    .insight-content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      .insight-info {
        display: flex;
        flex-direction: column;
        //   gap: 0.5rem;

        .categories {
          display: flex;
          gap: 1rem;
          span {
            font-size: 0.75rem;
            line-height: 1.875rem;
            color: $red;
          }
        }

        .title {
          font-family: $sans-serif-bold;
          font-size: 1.5rem;
          line-height: 2rem;
          text-overflow: ellipsis;
          /* Needed to make it work */
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }

  .frame-box-wrapper {
    &:hover {
      &::after,
      &::before {
        opacity: 1;
        width: 80px;
        height: 80px;

        @include desktop {
          width: 60px;
          height: 60px;
        }
      }

      .corner-div {
        opacity: 1;
        width: 80px;
        height: 80px;
        @include desktop {
          width: 60px;
          height: 60px;
        }
      }
    }
  }
}
