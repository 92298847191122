.autoren {
  &.has-white-bg {
    .autoren-grid {
      &:hover {
        background-color: $light-grey;
      }

      .autoren-grid-box {
        border: 2px solid $light-grey;
      }
    }

    .autoren-slide {
      border: 2px solid $light-grey;
    }
  }

  &.has-grey-bg {
    .autoren-grid {
      &:hover {
        background-color: $white;
      }
      .autoren-grid-box {
        border: 2px solid $white;
      }
    }

    .autoren-slide {
      border: 2px solid $white;
    }
  }

  &.has-darkgrey-bg,
  &.has-dark-grey-bg {
    .autoren-grid {
      &:hover {
        * {
          color: #000;
        }
        background-color: $medium-grey;
      }

      .autoren-grid-box {
        border: 2px solid $medium-grey;
      }
    }

    .autoren-slide {
      border: 2px solid $medium-grey;
    }
  }

  .autoren-grid {
    &:hover {
      &::after,
      &::before {
        opacity: 1;
        width: 80px;
        height: 80px;

        @include desktop {
          width: 60px;
          height: 60px;
        }
      }

      .corner-div {
        opacity: 1;
        width: 80px;
        height: 80px;
        @include desktop {
          width: 60px;
          height: 60px;
        }
      }

      .autoren-grid-box {
        .image-wrapper {
          img {
            transform: scale(1.1);
          }
        }

        .arrow-wrapper {
          .arrow {
            svg {
              path {
                stroke: $red;
              }
            }

            .arrow-text {
              color: $red;
              opacity: 1;
              transform: translate(0, 0);
            }
          }
        }
      }
    }
  }

  .autoren-grid {
    position: relative;
    margin-top: 1.25rem;
    transition: background-color 0.3s ease-in;
    &::after,
    &::before {
      transition: opacity 0.3s ease-in, width 0.3s ease-in-out,
        height 0.3s ease-in-out;
      position: absolute;
      width: 0;
      height: 0;
      background: $aunds-gradient;
      content: " ";
      opacity: 0;
      z-index: 1;
    }

    &::after {
      top: 0;
      left: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
      transform: rotate(270deg);
    }
    &::before {
      top: 0;
      right: 0;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
    }

    .corner-div {
      position: absolute;
      transition: opacity 0.3s ease-in, width 0.3s ease-in-out,
        height 0.3s ease-in-out;
      width: 0;
      height: 0;
      background: $aunds-gradient;

      &:first-of-type {
        bottom: 0;
        right: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
        transform: rotate(90deg);
      }

      &:nth-of-type(2) {
        bottom: 0;
        left: 0;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 97% 100%, 97% 3%, 0 3%);
        transform: rotate(180deg);
      }
    }

    .autoren-grid-box {
      padding: 1rem;

      .image-wrapper {
        justify-items: start;
        @include tablet {
          justify-content: center;
        }
      }

      .text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 3rem;
        overflow: hidden;
        @include tablet {
          max-width: 80%;
        }
      }

      .arrow-wrapper {
        padding-right: 2.5rem;
        // padding-bottom: 2.5rem;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      }
      .arrow {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        margin-top: 1.5rem;

        @include desktop {
          margin-top: 0;
        }

        .arrow-text {
          transition: 0.3s ease-in-out;
          opacity: 0;
          transform: translate(-15px, 0);
          font-size: 0.8rem;
          white-space: nowrap;
        }
      }
    }
  }

  .image-wrapper {
    display: grid;
    width: 100%;
    padding: 0 1rem;
    align-items: center;

    .is-120x120,
    .is-150x150 {
      border-radius: 50%;
      overflow: hidden;
    }

    .is-120x120 {
      width: 120px;
      height: 120px;
    }

    .is-150x150 {
      width: 150px;
      height: 150px;
    }

    img {
      transition: transform 0.3s ease-in-out;
    }
  }

  .autoren-link {
    color: inherit;
    text-decoration: none;
    pointer-events: auto;

    &:hover {
      text-decoration: none;
    }
  }

  .autoren-slider {
    .embla-slider {
      @include reset-ul-li;
      overflow: hidden;
      .embla-container {
        display: flex;
        height: 100%;

        .embla-slide {
          margin-right: 1rem;
          min-width: 0;
          height: initial;
          flex: 0 0 100%;

          @include tablet {
            flex: 0 0 50%;
          }

          @include desktop {
            flex: 0 0 33.33%;
          }

          @include widescreen {
            flex: 0 0 20%;
          }

          a {
            text-decoration: none;
          }
        }
      }
    }

    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .autoren-swiper-wrapper {
      @include desktop {
        width: calc(100vw - ((100vw - 100%) / 2));
      }
    }

    .autoren-slide {
      // min-width: 313px;
      padding: 2.5rem 1rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      gap: 1.5rem;
      text-align: center;
      height: 100%;

      .content-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 1.5rem;
      }

      .autoren-link {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;
      }

      .autoren-slide-info {
        span {
          display: block;
        }
      }

      .image-wrapper {
        justify-items: center;
      }
    }

    .autoren-slider-navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      // padding: 0 1.5rem;

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: inherit;
      }
    }
  }
}
