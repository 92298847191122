.react-tooltip {
  &.glossar-tooltip {
    color: $dark-grey;
    background-color: $white;
    box-shadow: 0 5px 16px #dddddd;
    font-size: 0.875rem;
    line-height: 1.56rem;
    max-width: 37.5rem;
    padding: 1.5rem;
    opacity: 1;
    z-index: 4;
    border-radius: 25px;
    * {
      font-size: 0.875rem;
      line-height: 1;
    }

    .tooltip-title {
      font-family: $sans-serif-bold;
      font-size: 1rem;
      display: block;
      margin-bottom: 1rem;
    }
  }
}
