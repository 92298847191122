.insight-blog-cast {
  display: grid;
  $width: calc(100vw - 74px);
  grid-template-columns: 74px $width;
  @include tablet {
    grid-template-columns: 74px 520px;
  }

  position: fixed;
  top: calc(45% - $navbar-height-desktop);
  right: 0.5em;
  z-index: 30;
  // padding: 1rem 0 1rem 1rem;
  overflow: hidden;
  pointer-events: none;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 74px 100%, 74px 74px, 0 74px);
  background: transparent
    linear-gradient(117deg, #5d2ec4 0%, #ff0b98 54%, #c6243b 100%) 0% 0%
    no-repeat padding-box;

  .action {
    pointer-events: all;
    width: 74px;
    height: 74px;
    // background-color: #5d2ec4;
    border-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .streaming-wrapper {
    pointer-events: all;
    .info-wrapper {
      display: flex;
      flex-direction: column;
      padding: 33px 0 45px 0;
      .title {
        font-size: 42px;
        line-height: 1;
        font-family: $sans-serif-bold;
        color: $white;
        text-align: center;
      }

      .icon-text {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;

        .text {
          font-size: 10px;
          color: $white;
          line-height: 25px;
        }

        .icon {
          display: flex;
          position: relative;
          width: 220px;
          height: 11px;
        }
      }

      .streaming-platforms {
        margin-top: 2.5rem;
        display: flex;
        justify-content: center;
        gap: 1.5rem;
        .streaming-lable {
          color: $white;
        }

        .platform {
          width: 43px;
          height: 24px;
        }
      }
    }

    .iframe-wrapper {
      position: relative;
      height: 130px;

      .sc-iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    .link-wrapper {
      background-color: #333333;
      padding: 22px 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .btn.is-primary {
        border: 1px solid #ffff;
        color: $white;
        line-height: 20px;
        text-align: center;
      }
    }
  }
}

.blogcast {
  .columns {
    width: 100%;

    .content-blocker__wrapper {
      width: calc(100% + 2rem) !important;
      height: unset;
    }
  }
}
