.socials {
  flex-direction: column;
  display: flex;

  .consent-wrapper {
    text-align: center;
    box-sizing: border-box;
    border: 1.4375rem solid $red;
    border-radius: 0px;
    &.content-blocker-wrapper {
      height: unset;
    }
    &.insta {
      margin: 0 0 1.5rem 0 !important;
    }
    &.fb,
    &.twitter {
      margin: 1.5rem 0 0 0 !important;
    }
    @include desktop {
      margin-top: $spaceXS;
    }
    .buttons {
      button {
        margin: 0 auto !important;
        background: $white;

        &.is-primary {
          &:hover {
            background: $red;
          }
        }
      }
      a {
        display: none;
        &.underline {
          text-decoration: underline;
        }
      }
    }
  }

  @include touch {
    .container {
      margin: 0;
    }
    &.no-padding-top {
      padding-top: 0;
    }
    &.no-padding-bottom {
      padding-bottom: 0;
    }
    .follow-btn-box {
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }
  }
  @include desktop {
    .columns {
      background: $white;
      padding: $spaceSM;
      justify-content: space-between;
    }
  }

  .twitter-timeline {
    width: 100% !important;
  }

  .white-frame {
    background-color: $white;
    border: 1px solid $light-grey;
    padding: $spaceXS;

    &.scrollable {
      overflow-y: scroll;
      max-height: 40rem;
    }

    @include touch {
      .fb-feed {
        padding-bottom: $spaceXS;
      }
    }
  }

  .load-more-container {
    width: 100%;
  }

  .feed-head {
    @include touch {
      background: $white;
      cursor: pointer;
      margin: 0;
      padding: $spaceXS;
      &.is-open {
        svg {
          transform: rotate(-90deg);
        }
      }
    }
    margin-bottom: $spaceSM;
    svg {
      height: 1.25rem;
      width: 1.25rem;
      path {
        fill: $red !important;
      }
      transform: rotate(90deg);
      transition: transform 250ms ease-in-out;
    }

    .feed-title {
      margin: 0;
    }
  }

  .feed-name {
    font-family: $sans-serif-bold;
    font-size: 0.85rem;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    padding: 0;
    margin-right: $spaceXS;
  }

  .feed-body {
    @include touch {
      height: 0;
      overflow: hidden;
      &.is-open {
        height: auto;
      }
    }
  }
}
