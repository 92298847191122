$mf-color-base: $black !default;
$mf-color-text: $white !default;

.mf-cursor {
  $root: &;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;

  direction: ltr;
  contain: layout style size;
  pointer-events: none;

  transition: opacity 0.3s, color 0.4s;

  @media (max-width: 425px) {
    display: none;
  }

  &:before {
    content: "";
    position: absolute;
    top: -50px;
    left: -50px;
    display: none;
    width: 100px;
    height: 100px;
    transform: scale(0.2);
    background: $mf-color-base;
    border-radius: 50%;
    // outline: 3px solid #fff;
    // outline-offset: 20px;
    transition: transform 0.25s ease-in-out, opacity 0.1s;
  }

  &::after {
    content: "";
    position: absolute;
    border: 3px solid $white;
    border-radius: 50%;
    width: 108px;
    height: 108px;
    top: -54px;
    left: -54px;
    display: none;
    transform: scale(0.2);
    transition: transform 0.25s ease-in-out;
  }

  &.-inverse {
    color: invert($mf-color-base);
  }

  &.-hide-pointer {
    &::before {
      display: none;
    }
    &::after {
      display: none;
    }
  }

  &.-exclusion {
    @supports (mix-blend-mode: exclusion) {
      mix-blend-mode: exclusion;

      &:before {
        background: invert($mf-color-base);
      }
    }
  }

  &.-pointer {
    &:before {
      transform: scale(0.15);
    }
  }

  &.-text {
    &:before {
      transform: scale(1.7);
    }

    &::after {
      transform: scale(1.7);
      border: 1px solid $white;
    }

    &.-active:before {
      transform: scale(1.6);
      transition-duration: 0.2s;
    }
  }

  &.-icon {
    &:before {
      transform: scale(1.5);
    }

    &.-active:before {
      transform: scale(1.4);
    }
  }

  &.-hidden {
    &:before {
      transform: scale(0);
    }
    &::after {
      transform: scale(0);
    }
  }

  &-text {
    position: absolute;
    top: -18px;
    left: -18px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0) rotate(10deg);

    opacity: 0;
    color: $mf-color-text;

    font-size: 20px;
    line-height: 30px;
    text-align: center;
    transition: opacity 0.4s, transform 0.3s;

    #{$root}.-text &,
    #{$root}.-icon & {
      opacity: 1;
      transform: scale(1);
    }
  }

  &-media {
    $size_default: 500px;
    position: absolute;

    width: $size_default;
    height: $size_default;
    margin: (-$size_default * 0.5) 0 0 (-$size_default * 0.5);

    @include ultrahd {
      $size: 30vw;
      width: $size;
      height: $size;
      margin: (-$size * 0.5) 0 0 (-$size * 0.5);
    }

    overflow: hidden;

    video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      border-radius: 50%;
      @supports (object-fit: cover) {
        position: static;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateZ(0);
      }
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      border-radius: 50%;
      @supports (object-fit: cover) {
        position: static;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translateZ(0);
      }
    }

    &-box {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      transform: scale(0) translateZ(0);
      padding: 100px;
      opacity: 0;
      border-radius: 50%;
      transition: transform 0.35s, opacity 0.2s 0.2s;

      #{$root}.-media & {
        opacity: 1;
        transform: scale(1);
        transition-duration: 0.4s, 0.4s;
        transition-delay: 0s, 0s;
      }
    }
  }

  // for making it small
  &.-small-size {
    .mf-cursor-media {
      $size_default: 400px;
      position: absolute;

      width: $size_default;
      height: $size_default;
      margin: (-$size_default * 0.5) 0 0 (-$size_default * 0.5);

      @include ultrahd {
        $size: 23vw;
        width: $size;
        height: $size;
        margin: (-$size * 0.5) 0 0 (-$size * 0.5);
      }
    }
  }
}
