.seo-page {
  ul {
    margin: 0;
    li {
      page-break-inside: avoid;
      break-inside: avoid-column;
    }
  }

  .seo-list {
    margin-top: 9.375rem;

    @include tablet {
      columns: 2;
    }

    @include fullhd {
      columns: 3;
    }
  }
  .seo-link {
    text-decoration: underline;
    text-underline-offset: 4px;
    &:focus,
    &:hover {
      text-decoration: underline;
    }

    &::after,
    &::before {
      content: none;
    }
  }
}
