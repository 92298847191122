.portfolio-slider {
  .portfolio-slide {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .left {
      position: relative;
      width: 310px;
      height: 100%;
    }
    .right {
      padding: 14px 26px;
      width: 280px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .top {
        .categories {
          display: flex;
          .category {
            padding: 0 2.5px;
            font-size: 12px;
            color: $red;
            &:not(:last-child)::after {
              position: relative;
              content: "|";
              left: 2.5px;
            }
          }
        }

        .title {
          font-family: $sans-serif-bold;
        }
        .content {
          height: 3rem;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
